import { FlexContainer } from "src/components/layout/elements"
import styled, { css } from "styled-components"
import { ReactComponent as CloseSvg } from '../assets/close.svg';
import { useState } from "react";
import api from "src/helpers/api";

export default function PersonFeedbackForm({ 
    onClose,
    rating
}: {
    onClose: () => void
    rating: number
}) {

    const [comment, setComment] = useState('');

    const onChange = (value: string) => {
        setComment(value);
    }

    const onSubmit = async () => {
        await api.indiAddPersonFeedback({
            rating,
            comment
        });
        onClose();
    }

    return <Container>
        <Form>
            <Header>
                <Title>Расскажите, почему вы так оценили</Title>
                <CloseContainer onClick={onClose}><CloseSvg /></CloseContainer>
            </Header>
            <TextArea value={comment} onChange={(e) => onChange(e.target.value)}></TextArea>
            <Button disabled={!comment} onClick={onSubmit}>Отправить</Button>
        </Form>
    </Container>
}

const Container = styled(FlexContainer)`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 1rem;
    z-index: 1000;
    
    background: rgba(29, 29, 29, 0.37);
    backdrop-filter: blur(16px);
    flex-direction: column;
    justify-content: end;
`

const Form = styled.div`
    background-color: white;
    box-shadow: 0px 6.4px 14.4px 0px rgba(58, 58, 68, 0.13);
    border-radius: 26px;
    padding: 1.5rem;
`

const Header = styled.div`
    margin-bottom: 1.25rem;
    display: flex;
    gap: 1rem;
`

const Title = styled.div`
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: left;
`

const CloseContainer = styled.div`
    cursor: pointer; 
`

const TextArea = styled.textarea`
    display: block;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 5.5rem;
    margin-bottom: 1.5rem;

    font-size: 0.875rem;
    color: var(--text-primary);
    padding: 1rem;
    font-family: inherit;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    outline: none;
    resize: none;
`

const Button = styled.div<{disabled?: boolean}>`
    background: rgba(123, 203, 85, 1);
    padding: 1rem;
    font-size: 0.875rem;
    border-radius: 66px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;

    ${p => p.disabled && css`
        pointer-events: none;
        opacity: 0.6;
    `}
    text-align: center;
`