import styled from "styled-components";
import store from '../../../../store/Store';
import { WINDOW_ID_PERSON, WINDOW_ID_PERSON_2 } from '../../helpers/constants';
import { windowsStore } from '../../../../store/Windows';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import PersonHeader from './components/PersonHeader';
import { history } from '../../../../MainRouter';
import { observer } from 'mobx-react-lite';
import { searchParams } from '../../../../helpers/url';
import { Grid, MoreButton } from './components/PersonMainBlock';
import { ReactComponent as MoreSvg } from './assets/more.svg';
import Cell from './components/Cell';
import { Indi } from '@chronos/api';
import { hashCode, relationsHashCode, useScrollToBlock, utfIcon } from './helpers';
import { sendProductGtm } from "../../helpers/gtm";
import PersonFeedback from './components/PersonFeedback';
import { BackButton } from './Person-3';
import { ReactComponent as BackSvg } from './assets/back.svg';
import { Gender } from "src/libs";
import { useEffect, useRef } from "react";


interface IPersonProps {
  data: Indi.GetPerson.IPersonRelations,
  scrollTo?: string;
  gender?: Gender
  onOpen3Level: (data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => void
}

export default observer(function Person2Relations({data, scrollTo, gender, onOpen3Level}: IPersonProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useScrollToBlock(scrollTo);

  const sp = searchParams();

  const userId = store.sessionData?.id
  const dataOpen = store.notes.dataOpen

  useEffect(() => {
    if (dataOpen?.screenLevel === 3) {
      const element = containerRef.current?.getElementsByClassName(`cell_${dataOpen?.title}`)[0]

      if (element && element instanceof HTMLElement) {
        element.click()
        store.notes.resetDataOpen()
      }
    }
  }, [dataOpen])

  const onBack = () => {
    sendProductGtm('PERSON', 'back_header', userId);
    windowsStore.close(WINDOW_ID_PERSON_2)
  }

  const onClose = () => {
    sendProductGtm('PERSON', 'close', userId);
    windowsStore.close(WINDOW_ID_PERSON)
    windowsStore.close(WINDOW_ID_PERSON_2)
    history.push({ pathname: '', search: sp.toString() })
  }

  const onNext = () => {
    sendProductGtm('PERSON', 'next_sphere', userId);
    windowsStore.close(WINDOW_ID_PERSON_2);
    document.getElementById(`purpose-more-button`)?.click();
  }

  const genderKey = gender === 'male' ? 'Male' : 'Female';

  return (
    <WindowContainer className={'window-container'} id="Person2Window" ref={containerRef}>
      <PersonHeader onBack={onBack} onClose={onClose} title={data.title} />
      <Container>


        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderIcon>{utfIcon(data.icon)}</MainBlockHeaderIcon>
                <MainBlockHeaderTitle>{data.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>{data.description}</MainBlockText>

            {/* <div>
                <Range from={'Одиночка'} to={'Партнер'} position={50} color="rgba(232, 81, 118, 1)" />
            </div> */}


        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.expectation.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.expectation.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.expectation.description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data.expectation.texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>


        <MainBlock id={`scrollTo${relationsHashCode}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data[`importantInPartner${genderKey}`].title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data[`importantInPartner${genderKey}`].description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data[`importantInPartner${genderKey}`].texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data[`needInMarriage${genderKey}`].title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data[`needInMarriage${genderKey}`].description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data[`needInMarriage${genderKey}`].texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data[`getWorldOffer${genderKey}`].title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data[`getWorldOffer${genderKey}`].description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data[`getWorldOffer${genderKey}`].texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>



        <MainBlock id={`scrollTo${hashCode(data.whereToMeet.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.whereToMeet.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.whereToMeet.description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data.whereToMeet.texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.friendOfHappiness.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.friendOfHappiness.description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data.friendOfHappiness.texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.enemiesOfHappiness.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.enemiesOfHappiness.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.enemiesOfHappiness.description}
            </MainBlockText>

            <MoreButton onClick={() => onOpen3Level(data.enemiesOfHappiness.texts, undefined, 'love_blocks', userId, 'Личная жизнь')}>Подробнее <MoreSvg /></MoreButton>

            <Grid>
                {data.enemiesOfHappiness.texts!.map(item =>
                    //@ts-ignore
                    <Cell icon={utfIcon(item.icon)} title={item.title} onClick={() => onOpen3Level(data.enemiesOfHappiness.texts, hashCode(item.title), 'love_blocks', userId, 'Личная жизнь')} />
                )}
            </Grid>
        </MainBlock>

        <PersonFeedback />

        <BackButton onClick={onNext} down>
            Перейти к предназначению
            <BackSvg />
        </BackButton>


      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`

const MainBlock = styled.div`
    margin-bottom: 2rem;
`

const MainBlockHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.675rem;
`

const MainBlockHeaderIcon = styled.div`
    font-size: 1.75rem;
`
const MainBlockHeaderTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 500;

`

const MainBlockText = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
`

const Item = styled.div`
    padding: 0.75rem;
    border-radius: 12px;
    background: rgba(246, 244, 242, 1);
    margin-bottom: 0.25rem;

`
