import dayjs from "dayjs";
import { computed, makeAutoObservable, runInAction } from "mobx";
import api from "../helpers/api";
import { Indi } from "../libs";

export interface INote extends Indi.IndiNotes.Note {
  recommendations: Indi.IndiNotes.Recommendation[];
}

interface AddNote extends Indi.IndiNotes.AddNote.Request {
  text: string
}

export default class Notes {
  constructor() {
    makeAutoObservable(this);
  }

  private _notes: INote[] = []

  dataOpen: any = null

  @computed
  get notesList() {
    return this._notes;
  }

  async buildNotes(userId: number, force?: boolean) {

    if (this._notes.length && !force) {
      return this._notes;
    }

    try {
      const notesResult: INote[] = await api.indiGetNotes(userId) as INote[]
      // const noteIds = notesResult.map(note => Number(note.id))

      // соберем Ids неактуальных заметок
      const notesForDelete: number[] = notesResult
        ?.filter((note) => dayjs().isAfter(dayjs(note.to)))
        ?.map(note => Number(note.id))

      // строим массив заметок с рекомендациями
      runInAction(() => {
        this._notes = notesResult.filter((note) => !notesForDelete?.includes(note.id)) // уберем заметку, если event уже не актуален
      })

      return this._notes
    } catch (e) {
      console.error(`Get notes error: ${e}`)
      throw e;
    }
  }

  async addToNote(data: AddNote) {
    try {
      const {
        userId,
        title,
        text,
        sphereName,
        from,
        to,
        product,
        productId,
        type,
        screenLevel,
      } = data

      const noteIndex = this._notes.findIndex(note => (
        (
          note.sphereName === sphereName &&
          note.title === title &&
          note.from === from &&
          note.to === to
        ) ||
        (
          note.product === product &&
          note.productId === productId &&
          note.title === title
        )
      ))

      // если заметка не найдена, то добавим ее
      if (noteIndex === -1) {
        const addNoteResult = await api.indiAddNote({ userId, sphereName, title, from, to, product, productId,  type, screenLevel})
        // const newNoteIndex = this._notes.findIndex(note => note.id === newNoteId)

        if (!addNoteResult) throw new Error('indiAddNote fail')

        const result = await api.indiAddRecommendation({
          noteId: addNoteResult.id,
          text,
        })

        const newNote: INote = {
          ...addNoteResult,
          recommendations: [{ ...result }],
          type: data.type,
          screenLevel: data.screenLevel,
        }

        runInAction(() => {
          this._notes = [...this._notes, newNote]
        })

      } else {

        const recommendation = {
          noteId: this._notes[noteIndex].id,
          text,
        }

        const result = await api.indiAddRecommendation(recommendation)

        this._notes[noteIndex].recommendations.push(result)

        runInAction(() => {
          this._notes = [...this._notes]
        })
      }
    } catch (e) {
      console.error(`Add recommendation error: ${e}`)
    }
  }

  async removeFromNote(noteId: number, recommendationId: number) {
    try {
      const removedNoteIds: number[] = [];
      const updatedNotes = this._notes
        .map(note => {
          if (note.id === noteId) {
            return {
              ...note,
              recommendations: note.recommendations.filter(item => item.id !== recommendationId)
            }
          } else {
            return note
          }
        })
        .filter(note => {
          if (note.recommendations.length > 0) {
            return true
          } else {
            removedNoteIds.push(note.id)
            return false
          }
        })

      runInAction(() => {
        this._notes = updatedNotes
      })

      await api.indiRemoveRecommendation({ id: recommendationId })

      removedNoteIds.length && await api.indiRemoveNote(removedNoteIds)

    } catch (e) {
      console.error(`Remove recommendation error: ${e}`)
      throw e;
    }
  }

  findNote(data: Omit<Indi.IndiNotes.AddNote.Request, 'userId'>) {
    const {
      sphereName,
      title,
      from,
      to,
      product,
      productId,
    } = data

    return this._notes.find((note: INote) => (
      (
        note.sphereName === sphereName &&
        note.title === title &&
        note.from === from &&
        note.to === to
      )
        ||
      (
        note.product === product &&
        note.productId === productId &&
        note.title === title
      )
    ))
  }

  async onClickBookmark(data: AddNote, isSaved: boolean) {
    if (isSaved) {
      const note = this.findNote({
        title: data.title,
        product: data.product,
        productId: data.productId,
      })

      const recommendationId = note?.recommendations.find(item => item.text === data.text)?.id

      if (!note || !recommendationId) return

      await this.removeFromNote(note.id, recommendationId)
    } else {
      await this.addToNote(data)
    }
  }

  setDataOpen(value: any) {
    this.dataOpen = value
  }

  resetDataOpen() {
    this.dataOpen = null
  }
}
