import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import ButtonSwitcher from '../../components/ui/ButtonSwitcher';
import { ROUTES } from '../../helpers/routes';
import { searchParams } from '../../helpers/url';
import { useMoveShutter } from '../../hooks/popup-hooks';
import { useBackWindow } from '../../hooks/router-hooks';
import { Logging } from '../../libs';
import { history } from '../../MainRouter';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { WindowHeader } from '../windows/components/WindowHeader';
import { Catalog } from './components/Catalog';
import { Purchase } from './components/Purchase';
import { PRODUCTS_SECTION, sectionsOptions } from './helpers/constants';


export const WINDOW_ID_PRODUCTS: string = 'WINDOW_ID_PRODUCTS';

export const openProducts = () => windowsStore.open(
  WINDOW_ID_PRODUCTS,
  <Products />,
  undefined,
  false,
  true,
)

export interface Discount {
	type: string
	value: number
}

export const Products = observer(() => {
  useBackWindow(ROUTES.PRODUCTS, WINDOW_ID_PRODUCTS);

  const [section, setSection] = useState(store.synastry.activeSection);

  const containerRef = useRef<HTMLDivElement | null>(null)

  const sp = searchParams();
  const shutterRef = store.shutter.shutterRef
  const isAuth = store.isAuth
  const activeSection = store.synastry.activeSection

  const setSectionHandler = (section: PRODUCTS_SECTION) => {
    store.synastry.setActiveSection(section);
    setSection(section);
  }

  useMoveShutter(containerRef, shutterRef)

  useEffect(() => {
    setSection(store.synastry.activeSection);
    containerRef.current?.scrollTo(0, 0)
  }, [activeSection])

  useEffect(() => {
    section === 'catalog'
      ? store.products.getProducts()
      : isAuth && store.products.getPurchasedProducts()
      document.title = `Продукты • ${section === 'catalog' ? 'Каталог' : 'Купленные'}`;
  }, [section, isAuth]);

  useEffect(() => {
    if (sp.get('my')) setSectionHandler(PRODUCTS_SECTION.PURCHASED);

    history.push({pathname: '/products', search: sp.toString()})
    store.logger.startLoggingSession(Logging.Screens.PRODUCTS)

    return () => {
      history.push({pathname: '/', search: sp.toString()})
      store.logger.createLoggingSession(Logging.Screens.PRODUCTS)
    }
  }, [])


  return (
    <WindowContainer ref={containerRef}>
      <WindowHeader title='Продукты'/>
      <Container className='no-print'>
        <ProductsContainer>
          <StyledButtonSwitcher
            options={sectionsOptions}
            value={section}
            onChange={setSectionHandler}
          />

          {
            section === PRODUCTS_SECTION.PURCHASED
              ? <Purchase/>
              : <Catalog/>
          }
        </ProductsContainer>
      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 8rem;
`

const StyledButtonSwitcher = styled(ButtonSwitcher)`
	margin-bottom: 1rem;
`
