import { AstroEvents } from './AstroEvents';
import Api from "../helpers/api";
import dayjs, { Dayjs } from 'dayjs';
import { makeAutoObservable, runInAction } from "mobx";
import { searchParams } from "../helpers/url";
import { profile } from "../helpers/profile";
import Prognoses from "./Prognoses";
import Products from "./Products";
import Synastry from "./Synastry";
import Payments from './Payments';
import Promocode from "./Promocode";
import Emotions from "./Emotions";
import Astrology from "./Astrology";
import Auth, { IAuthIndi } from "./Auth";
import Events from "./Events";
import Solar from "./Solar";
import { SERVICE_ID } from "../helpers/ls/consultations";
import Partner from "./Partner";
import Tariffs from "./Tariffs";
import { Trial } from "./Trial";
import { IUsersPullBannerData } from "./Banners/type/banners";
import { LS_KEY_usersPullBanner_clicked } from "../components/UsersPullBanner";
import { Logger } from "./Logger";
import {
  IInfoEx,
  IServiceDataEx,
  ISession,
  ISessionBase,
  ISkillEx,
  IUser,
  IWorkProfileEx,
  IWorkProfilesItem,
  SessionCreator,
  SessionFormat,
  SessionStatus,
  WorkFormat,
  fromDateTime
} from "../libs";
import Notes from "./Notes";
import { Widgets } from './Widgets';
import { WebPush } from './WebPush';
import { Modal } from './Modal';
import { Rectification } from './Rectification';
import { Subscription } from './Subscribtion';
import { Support } from './Support';
import { Shutter } from './Shutter';
import { isHasSubscriptionOrTrial } from '../modules/paywall/helpers/tariffs';
import { Onboarding } from './Onboarding';
import { Banners } from './Banners/Banners';
import { Profile } from './Profile';
import { Pwa } from './Pwa';
import { PersonalityDescription } from './PersonalityDescription';
import Person from './Person';
import api from '../helpers/api';
import { Errors, getErrorInfo } from 'src/helpers/error';
import { openEditWindow } from 'src/modules/edit/Edit';
import { windowsStore } from './Windows';
import { Ui } from './Ui';


interface IConnectWithProfessional {
  openChat: boolean;
  session: ISession | null;
}

interface IProfile extends IUser {
  originalDate?: string;
}

class Store {
  prognoses = new Prognoses()
  products = new Products()
  synastry = new Synastry()
  emotions = new Emotions()
  promocode = new Promocode()
  payments = new Payments()
  astrology = new Astrology()
  events = new Events()
  auth = new Auth()
  solar = new Solar()
  person = new Person()
  partner = new Partner()
  tariffs = new Tariffs()
  trial = new Trial()
  logger = new Logger()
  notes = new Notes()
  astroEvents = new AstroEvents()
  widgets = new Widgets()
  webPush = new WebPush()
  modal = new Modal()
  rectification = new Rectification()
  subscription = new Subscription()
  support = new Support()
  shutter = new Shutter()
  onboarding = new Onboarding()
  banners = new Banners()
  userProfile = new Profile()
  pwa = new Pwa()
  personalityDescription = new PersonalityDescription()
  ui = new Ui()


  profile: IProfile = profile;
  sessionData: IAuthIndi | null = null;
  profileSessions: ISession[] | null = null;

  isAuth = false;
  isAuthFinished = false;
  visitLanding = false;

  professionalList: IWorkProfilesItem[] | null = null;
  professionals: IWorkProfilesItem[] | null = null;
  professional: IWorkProfileEx | null = null;

  services: Record<number, IServiceDataEx> | null = null;
  educations: Record<number, IInfoEx> | null = null;
  serviceCategories: Record<number, IInfoEx> | null = null;
  skills: Record<number, ISkillEx> | null = null;
  specializations: Record<number, IInfoEx> | null = null;
  rates?: { [key: string]: number }

  scheduleSlots: string[][] | null = null;

  currentService: IServiceDataEx | null = null;

  connectWithProfessional: IConnectWithProfessional = {
    openChat: false,
    session: null,
  }

  ls: any = {};

  systemAlertType: string = 'success'
  systemAlertMessage: string = 'system alert'
  systemAlertShow: boolean = false

  initialized: boolean = false

  constructor() {
    makeAutoObservable(this);
  };

  async init() {
    const start = Date.now();

    this.webPush.postMessage({
      vapidPublicKey : process.env.REACT_APP_VAPID_PUBLIC_KEY,
      urlBackend : process.env.REACT_APP_BACKEND,
    })

    await this.initLogin();
    await this.getData();
    await this.setSessions();
    await this.setAllProfessionals();
    await this.products.getProducts();
    this.partner.setPartner()

    const serviceId = this.lsGet(SERVICE_ID);
    const format = this.lsGet('session-format') || SessionFormat.Online;

    if (serviceId) {
      this.setCurrentService(+serviceId);
      await this.setProfessionals(+serviceId, format);
    }

    const professionalId = this.lsGet('professional-id');

    if (professionalId && !this.professional) {
      await this.setProfessional(+professionalId);

      const isWriting = format === SessionFormat.Offline;
      await this.setScheduleSlots(+professionalId, isWriting, undefined, this.currentService?.duration);
    }

    if (this.sessionData?.indi?.trialExpiryDate) {
      store.trial.setActiveTrial(this.sessionData.indi.trialExpiryDate)
    }

    this.logger.initLoggingSessionCommon()

    if(this.sessionData?.id) {
      this.webPush.postMessage({ userId: this.sessionData.id })
      await this.notes.buildNotes(this.sessionData.id)
    }

    this.initialized = true

    const timerTime = Date.now() - start;
    const userId = this.sessionData ? this.sessionData.id : 0
    const repository = 'indi'
    const data = {userId, time: timerTime, repository}
    api.sendTimeLoadNotify(data)
  };

  async initLogin(token?: string) {
    try {
      let sessionData = null;
      await this.signIn(token);

      if (this.lsGet('token')) {
        sessionData = await this.getProfile();
      }
      this.isAuthFinished = true;
      this.events.setStatus();

      return sessionData;
    } catch(e) {
      console.error(`init login error: ${e}`)

      throw new Error(`${e}`)
    }
  }

  async signIn(token_?: string) {
    const sp = searchParams();
    const token = token_ || (sp.get('token') ?? sp.get('code'))

    if (token && token !== 'weak') {
      try {
        const result = await Api.signIn(token, 'indi');
        this.sessionData = result;
        this.lsSave('token', result.token);
      } catch (e) {
        console.log(e);
      }
    } else if (!token) {
      // this.auth.goToAuth({ weak: true });
    }
  }

  async getProfile() {
    try {
      const profile = await Api.profile();
      const _profile = this.convertProfile(profile);

      await this.refresh();
      this.isAuth = true;

      this.updateProfile(_profile);

      if(this.sessionData?.id) {
        await this.notes.buildNotes(this.sessionData.id)
        this.pwa.setUserUsesPwa(this.sessionData?.id, profile.usesPwa)

        this.subscription.showSubscribeBanner()
      }

      this.isAuth = true;

      return this.sessionData
    } catch (e) {
      console.log('Unauthorized - ', e);
      this.isAuth = false;
      return null
    } finally {
      this.isAuthFinished = true;
      // return null
    }
  };

  async updateProfile(newProfile: IUser, remoteUpdate: boolean = true) {
    // Если в профиле уже есть персональные данные (они могут там появиться после заполнения формы персональных данных перед авторизацией task #6065),
    // мержим их
    const { sessionId: currentSessionId, id: currentPofileId, firstName: currentFirstName, birth: currentBirth, email: currentEmail } = this.profile;
    const isAfterAuth = currentSessionId == -1 && currentPofileId == -1
    const hasPrePersonalData = Boolean(currentFirstName && currentBirth.dateTime && currentBirth.place?.name)

    const hasSubscriptionOrtrial = isHasSubscriptionOrTrial(this.sessionData!)

    if (isAfterAuth && hasPrePersonalData && !hasSubscriptionOrtrial) {
      newProfile.firstName = newProfile.firstName || currentFirstName;
      newProfile.birth.dateTime = newProfile.birth.dateTime || currentBirth.dateTime;
      newProfile.birth.place = newProfile.birth.place?.name ? newProfile.birth.place : currentBirth.place;
      newProfile.email = newProfile.email || currentEmail;
    }

    runInAction(async () => {
      try {
        this.profile = newProfile;
        (remoteUpdate && this.sessionData && hasPrePersonalData) && await Api.updateProfileNew(this.sessionData.id, newProfile);
      } catch(e) {
        console.error(`Update Profile Error: ${e}`)

        const errorInfo = getErrorInfo(String(e))

        if (errorInfo.message === Errors.EMAIL_OR_PHONE_EXIST) {
          windowsStore.closeAll()

          openEditWindow({
            emailError: Errors.EMAIL_OR_PHONE_EXIST,
            onProfileUpdate: store.payments.moveUserToPaymentScreen,
          })
        }

        throw new Error(errorInfo.message)
      }
    })

    return newProfile
  };

  async refresh() {
    const token = this.lsGet('token')?.refresh

    if (token) {
      const result = await Api.refreshToken(token, 'indi');

      this.sessionData = result;
      this.events.setStatus();

      return JSON.parse(JSON.stringify(this.sessionData))
    }
    return null
  }

  async setSessions() {
    if (this.sessionData) {
      this.profileSessions = await this.getSessions(this.sessionData.id);
      return this.profileSessions
    } else {
      return null
    }
  };

  async getSessions(userSessionId: number) {
    let sessions = await Api.userSessions(userSessionId, {
      from: dayjs('1970-01-01T00:00:00.000Z').toISOString(), // include passed consultations for rating
      isClient: true,
      // @ts-ignore
      showTesters: new URLSearchParams(window.location.search)?.get('showTesters') ?? undefined
    });

    const paid = sessions.filter(session => session.type !== 'unschedule' && session.data.status === SessionStatus.Paid);
    const finished = sessions.filter(session => session.data.status === SessionStatus.Finished);
    const another = sessions.filter(session => session.type !== 'unschedule' && (session.data.status !== SessionStatus.Paid && session.data.status !== SessionStatus.Finished));
    const unschedule = sessions.filter(session => session.type === 'unschedule' && session.data.status !== SessionStatus.Finished)
    return [...unschedule, ...paid, ...another, ...finished];
  };

  // FIXME: refactor
  convertProfile(profile: IUser) {
    // @ts-ignore
    profile.originalDate = (profile.birth.dateTime as any)?.originalDate || profile.originalDate

    if (profile.birth?.dateTime && typeof profile.birth.dateTime !== 'string') {
      // @ts-ignore
      profile.birth.dateTime = fromDateTime(profile.birth.dateTime.date, profile.birth.dateTime.time);
    }

    if (profile.birth && !profile.birth.place) {
      // @ts-ignore
      profile.birth.place = null;
    }

    return profile;
  };

  lsInit = (key: string, defaultValue: any) => {
    if (!this.ls[key]) {
      const data = this.lsGet(key);
      this.lsSet(key, data || defaultValue);
    }
  }

  lsGet = (key: string) => {
    const data = localStorage.getItem(key);
    if (data) {
      try {
        const parsed = JSON.parse(data);
        return parsed;
      } catch {
        return null;
      }
    }
    return null;
  }

  lsSave = (key: string, value: any) => {
    const val = value || this.ls[key];
    if (val) {
      const stringified = JSON.stringify(val);
      localStorage.setItem(key, stringified);
      this.lsSet(key, val);
    } else {
      this.lsRemove(key);
    }
  }

  lsSet = (key: string, value: any) => {
    runInAction(() => {
      this.ls = {
        ...this.ls,
        [key]: value,
      };
    })
  }

  lsRemove = (key: string) => {
    localStorage.removeItem(key);
    this.lsSet(key, null);
  }

  async getData() {
    //const partner = await localStorage.getItem('partner');
    const data = await Api.workDatabase({
      showHidden: true,
    }); //, partner
    this.educations = data.education;
    this.serviceCategories = data.serviceCategories;
    this.services = data.services;
    this.skills = data.skills;
    this.specializations = data.specializations;
    this.rates = data.rates
  };

  getAllSpecializations() {
    return { ...(this.specializations || {}) }
  }

  getService(id: number): IServiceDataEx | null {
    let service = this.professional?.services?.find(service => service.id === id);
    if (!service) {
      service = Object.values(this.services || {}).find(service => service.id === id);
    }
    return service || null;
  };

  getServiceInList(id: number): IServiceDataEx | null {
    return Object.values(this.services || {}).find(service => service.id === id) ?? null;
  }

  async setProfessional(id: number | null) {
    if (id === null) {
      this.professional = null
    } else {
      const result = await Api.workProfile(id);
      this.professional = result;
      return result
    }
  };

  async setProfessionals(serviceId: number, format: number = 0) {
    this.professionals = (await Api.workProfiles({
      serviceId,
      hasSchedule: true,
      isActive: true,
      formats: [WorkFormat.indi],
      pageSize: 1000,
      // interviewStatus: InterviewStatus.Succeed,
      format,
      // @ts-ignore
      showTesters: new URLSearchParams(window.location.search)?.get('showTesters') ?? undefined
    })).items;// TODO: pagination
  };

  getScheduleSlots(professionalId: number, isWriting?: boolean, isPredictOfTheYear?: boolean, duration?: number) {
    return Api.scheduleSlots(
      professionalId,
      {
        from: dayjs().add(isWriting ? 5 : isPredictOfTheYear ? 5 : 2, 'day').toISOString(),
        to: dayjs().add(2, 'month').endOf('day').toISOString(),
        clientId: this.sessionData?.id,
        duration: duration ? +duration : undefined,
      }
    );
  };

  async setScheduleSlots(professionalId: number, isWriting?: boolean, isPredictOfTheYear?: boolean, duration?: number) {
    this.scheduleSlots = await this.getScheduleSlots(professionalId, isWriting, isPredictOfTheYear, duration) as string[][];
  };

  async setAllProfessionals() {
    this.professionalList = (await Api.workProfiles({
      isActive: true,
      hasSchedule: true,
      formats: [WorkFormat.indi],
      pageSize: 1000,
      // interviewStatus: InterviewStatus.Succeed
      // @ts-ignore
      showTesters: new URLSearchParams(window.location.search)?.get('showTesters') ?? undefined
    })).items; // TODO: pagination
  };

  setCurrentService(serviceId: number) {
    if (this.services || this.professional?.services) {
      const profServices = Object.values(this.professional?.services || {});
      const userServices = Object.values(this.services || {});

      const foundService = profServices.find(service => service?.id === serviceId) || userServices.find(service => service?.id === serviceId);
      foundService ? this.currentService = foundService : this.currentService = null;
    }
  };

  clearStorage() {
    this.lsRemove(SERVICE_ID);
    this.lsRemove('professional-id');
    this.lsRemove('active-time');
    this.lsRemove('session-id');
    this.lsRemove('is-pick-service');
    this.lsRemove('session-format');
    this.lsRemove('predictions');
  };

  async getSession(sessionId: number): Promise<ISession> {
    return await Api.getSession(sessionId) as Promise<ISession>;
  };

  async getMeeting(sessionId: number): Promise<ISession> {
    return await Api.getSessionMeeting(sessionId) as Promise<ISession>;
  };

  async addSession(service: IServiceDataEx, professional: IWorkProfileEx, time: string, sessionFormat: SessionFormat, creator: SessionCreator): Promise<number> {
    const price = professional.services.find(item => item.id === service.id)?.price ?? service.price

    const data = buildSession(
      this.sessionData?.id || null,
      this.profile,
      {
        ...service,
        price: {
          ...price,
          discount: this.partner.partnerDiscount,
        },
      },
      professional,
      time,
      sessionFormat,
      creator,
    );
    return await Api.addSession(data) as Promise<number>;
  };

  async updateSession(sessionId: number, session: ISessionBase) {
    return await Api.updateSession(sessionId, session) as Promise<number>;
  };

  async cancelSession(sessionId: number) {
    return await Api.cancelSession(sessionId) as Promise<number>;
  };

  systemAlert(type: string, message: string, showTime?: number) {
    this.systemAlertType = type;
    this.systemAlertMessage = message;
    this.systemAlertShow = true

    setTimeout(() => {
      runInAction(() => this.systemAlertShow = false)
    }, showTime || 1500);
  }

  async signOut(withoutRedirect = false) {
    try {
      this.sessionData && await Api.signOut(+this.sessionData.sessionId);
      this.isAuth = false;
      this.sessionData = null;
      this.profileSessions = null;
      this.isAuthFinished = false;
      this.profile = profile;
      this.clearStorage();
      this.lsRemove('token');

      if (!withoutRedirect) window.location.href = '/?token=weak';
    } catch (e) {
      console.log('Unauthorized');
    }
  };

  async safeSignOut() {
    try {
      this.sessionData && await Api.signOut(+this.sessionData.sessionId);
      this.lsRemove('token');
    } catch (e) {
      console.log('Unauthorized');
    }
  };

  getIndiDates() {
    const startPlan = this.sessionData?.indi?.startDate!;
    const expiryDate = this.sessionData?.indi?.expiryDate;
    const trialExpiryDate = this.sessionData?.indi?.trialExpiryDate;
    const endPlan = (expiryDate || trialExpiryDate)!;
    return { startPlan, endPlan }
  }

  get hasIndiSubscriptionOrTrial(): boolean {
    const currentDay: Dayjs = dayjs();
    const { expiryDate, trialExpiryDate } = this.sessionData?.indi || {};
    return Boolean(
      (expiryDate && currentDay.isBefore(expiryDate)) ||
      (trialExpiryDate && currentDay.isBefore(trialExpiryDate))
    );
  }

  get hasCanceledSubscriptionOrTrial(): boolean {
    const currentDay: Dayjs = dayjs();
    const { expiryDate, trialExpiryDate } = this.sessionData?.indi || {};
    return Boolean(
      (expiryDate && currentDay.isAfter(expiryDate)) ||
      (trialExpiryDate && currentDay.isAfter(expiryDate))
    );
  }

  get userNotUseIndi(): boolean {
    const { tariffId, expiryDate, trialExpiryDate } = this.sessionData?.indi || {};
    return Boolean(!tariffId && !expiryDate && !trialExpiryDate);
  }

  get indiBannerCustomization(): IUsersPullBannerData & { userId?: number } {
    const result = { ...this.sessionData?.indi?.customization!, userId: this.sessionData?.id };
    return result
  }

  get isShowUsersPullBanner(): boolean {
    const bannerCustomization = this.sessionData?.indi?.customization!;

    const hasSubscription = store.sessionData?.indi?.tariffId;
    const activeTrial = this.trial?.activeTrial;
    const isBannerOverdue = dayjs().isAfter(dayjs(bannerCustomization?.startShow).add(bannerCustomization?.duration, 'day'));
    const isBannerClicked = store.lsGet(LS_KEY_usersPullBanner_clicked);

    return Boolean(hasSubscription && !activeTrial && !isBannerOverdue && !isBannerClicked);
  }
}



export const buildSession = (
  clientId: number | null,
  profile: IUser,
  service: IServiceDataEx,
  professional: IWorkProfileEx,
  time: string,
  sessionFormat: SessionFormat,
  creator: SessionCreator,
): ISessionBase => {
  const data: ISessionBase = {
    userId: professional.userId,
    clientId,
    start: time,
    creator,
    data: {
      formId: -1,
      status: SessionStatus.Init,
      serviceId: service.id,
      isMutable: false,
      duration: service.duration || 60,
      fullName: profile.firstName,
      exactTime: true,
      price: service.price,
      phoneNumber: profile.phoneNumber || '',
      birth: profile.birth,
      rate: 0,
      comment: '',
      email: profile.email || '',
      format: sessionFormat,
      note: ''
    },
    // @ts-ignore
    type: 'session'
  };

  return data;
}

const store = new Store();

export default store;
