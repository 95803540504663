export enum Errors {
  EMAIL_OR_PHONE_EXIST = 'email or phone exist'
}

export function getErrorInfo(error: string) {
  const errorInfo: Record<string, string> = {}

  error
    .slice(error.lastIndexOf('Error: ') + 7)
    .split('; ')
      .forEach(item => {
        const arr = item.split(': ')
        errorInfo[arr[0]] = arr[1]
      })

  return errorInfo
}
