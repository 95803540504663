import styled from "styled-components";
import { ReactComponent as InfoYellowIcon } from '../assets/info-yellow.svg';
import { ReactComponent as InfoGreenIcon } from '../assets/info-green.svg';
import { ReactComponent as InfoRedIcon } from '../assets/info-red.svg';
import { ReactComponent as InfoOrangeIcon } from '../assets/info-orange.svg';

export type TType = 'yellow' | 'suitable' | 'notSuitable' | 'neutral' | 'green';

type TTheme = Record<TType, any>

export const theme: TTheme = {
    'yellow': {
        icon: <InfoYellowIcon />,
        color: 'rgba(229, 201, 52, 0.1)'
    },
    'suitable': {
        icon: <InfoGreenIcon />,
        color: 'rgba(165, 232, 97, 0.1)'
    },
    'notSuitable': {
        icon: <InfoRedIcon />,
        color: 'rgba(238, 59, 48, 0.1)'
    },
    'neutral': {
        icon: <InfoOrangeIcon />,
        color: 'rgba(229, 201, 52, 0.1)'
    },
    'green': {
        icon: null,
        color: 'rgba(165, 232, 97, 0.1)'
    }
}

export default function InfoBlock({
    type,
    text
}: {
    type: TType,
    text: string
}) {
    return <Container color={theme[type].color}>
        {theme[type] && <Icon>{theme[type].icon}</Icon> }
        <Text>{text}</Text>
    </Container>
}

const Container = styled.div<{color: string}>`
    border-radius: 12px;
    padding: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.04);
    display: flex;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
    background: ${p => p.color};

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`

const Icon = styled.div`
    width: 1.5rem;
`

const Text = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
`
