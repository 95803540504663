import React from 'react';
import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../components/layout/elements';
import { ChevronLeft, Cross } from '../../../assets/icons/system/system-icons';
import { windowsStore } from '../../../store/Windows';

export enum IconStyles {
  WITH_BG = 'with_bg',
}

export const WindowHeader = ({
  title = '',
  subtitle = '',
  onBack,
  className,
  iconStyle,
  onClose,
}: {
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  onBack?: () => void
  className?: string
  iconStyle?: IconStyles
  onClose?: () => void
}) => {

  const renderRightPart = () => {
    if (onClose) {
      return (
        <IconContainer
          background={iconStyle === IconStyles.WITH_BG}
          onClick={onClose}
        >
          <Cross />
        </IconContainer>
      )
    }

    if (isRenderRightPart()) {
      return windowsStore.contextMenu
    }
    return <></>
  }

  const isRenderRightPart = () => {
    return !!windowsStore.contextMenu;
  }

  return <Container className={`no-print ${className}`} rightPart={isRenderRightPart()}>
    {
      onBack && (
        <LeftSide>
          <IconContainer
            background={iconStyle === IconStyles.WITH_BG}
            onClick={onBack}
          >
            <ChevronLeft/>
          </IconContainer>
        </LeftSide>
      )
    }

    <CenterSide>
      <div className='title'>{title}</div>
      {Boolean(subtitle) && <div className='subtitle'>{subtitle}</div>}
    </CenterSide>

    <RightSide>{renderRightPart()}</RightSide>
  </Container>
}

const Container = styled(FlexContainer)<{ rightPart: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;


  padding: 1rem;

  ${p => p.rightPart && css`
    justify-content: space-between;
  `}

  padding: 1.5rem 1rem 1rem 1rem;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.9);

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`
const CenterSide = styled.div`
  /* display: flex; */
  position: relative;
  /* align-items: center; */
  font-weight: 500;

  & .title {
    font-size: 1.25rem;
  }
`;

const LeftSide = styled.div`
`

const RightSide = styled.div`
`

const IconContainer =  styled.div<{background: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;

  ${p => p.background && css`
    background: var(--input-background);
    border-radius: 50%;
  `}

  svg {
    width: 2rem;
    height: 2rem;
    margin: 0;
    opacity: 0.4;
  }
`
