import { observer } from 'mobx-react-lite';
import React from 'react';
import styled, { css } from "styled-components";
import { IPurchasedProduct } from '../../../store/Products';
import store from '../../../store/Store';
import { SOLAR, SYNASTRY, PERSON } from '../helpers/constants';
import { Content, Description, Emoji, IconContainer, ProductCardContainer, Title } from './products-card';
import { Badge, IndiProductCode } from '../../../libs';
import { getProductTitle } from '../helpers/content';

interface IPurchasedProductCardProps {
  data: IPurchasedProduct
  onClick(): void
}

export default React.memo(observer(function PurchasedProductCard(props: IPurchasedProductCardProps) {
  const hasBadge = Boolean(props.data.isTrial || props.data.solarBirthDate)

  const getStatus = (product: string) => {
    switch (product) {
      case SYNASTRY:
        if (props.data.isTrial) {
          return 'trial'
        }
        break
      case SOLAR:
        if (props.data?.periods) {
          return store.solar.getStatus(props.data?.periods[0].start, props.data?.periods[props.data.periods.length - 1].end, 'product-card')?.type
        }
        break
    }
  }

  const getStatusText = (product: string) => {
    switch (product) {
      case SYNASTRY:
        if (props.data.isTrial) {
          return 'Пробная версия'
        }
        break
    case SOLAR:
      if (props.data?.periods) {
        return store.solar.getStatus(props.data?.periods[0].start, props.data?.periods[props.data.periods.length - 1].end, 'product-card')?.text
      }
      break
    }
  }

  const partner1 = `${props.data?.partner1?.name} ${props.data.partner1?.date}`;
  const partner2 = `${props.data?.partner2?.name} ${props.data.partner2?.date}`;

  const personDescription = `${props.data.data?.name} ${props.data.data?.date} ${props.data.data?.place}`

  return (
    <PurchasedProductCardContainer onClick={props.onClick}>
      {hasBadge && <BadgeWrapper><CustomBadge value={getStatusText(props.data.code) ?? ''} status={getStatus(props.data.code)}/></BadgeWrapper>}

      <Header>
        {props.data.emoji && <IconContainer background={props.data.emoji.background}><Emoji>{props.data.emoji.text}</Emoji></IconContainer>}
        <Title>{getProductTitle(props.data.code as IndiProductCode, true)}</Title>
      </Header>

      <Content>
        {props.data.code === SYNASTRY && <Description><PartnerName>{partner1}</PartnerName> и <PartnerName>{partner2}</PartnerName></Description>}
        {props.data.code === SOLAR && <Description>{props.data.place2}</Description>}
        {props.data.code === PERSON && props.data.data && <Description>{personDescription}</Description>}
      </Content>
    </PurchasedProductCardContainer>
  )
}))

const PurchasedProductCardContainer = styled(ProductCardContainer)`
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: end;
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const PartnerName = styled.span`
  text-transform: capitalize;
`

const CustomBadge = styled(Badge)<{status: string | undefined}>`
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 3.5rem;
  padding: 0.4rem 0.7rem;
  color: var(--color-orange);
  background: var(--color-orange-bg);

  ${p => p.status === 'takePlace' && css`
    color: var(--color-green);
    background: var(--color-green-bg-transparent);
  `}

  ${p => p.status === 'end' && css`
    color: var(--text-secondary);
    background: none;
  `}

  ${p => p.status === 'trial' && css`
    color: var(--text-secondary);
    background: var(--color-gray-bg-transparent);
  `}
`
