import { makeAutoObservable } from 'mobx';
import api from '../helpers/api';



export default class Person {
  constructor() {
    makeAutoObservable(this);
  }

  async getPerson(purchasedId: number) {
    try {
      const result = await api.indiGetPersonDescription({purchasedId})
      return result
    } catch(e) {
      console.error(e)
    }
  }

  
}
