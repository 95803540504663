export const enum ROUTES {
  AUTH = 'auth',

  PROFILE = 'profile',
  EDIT = 'profile/edit',
  UNSUBSCRIBE = 'profile/unsubscribe',

  PAYWALL = 'paywall',
  PAYLANDING = 'paylanding',
  REGISTRATION = 'registration',
  PAYMENT_RESULT = 'payment-result',

  MOONPHASE = 'moonphase',
  ASTRO_EVENT = 'astro-event',

  PROGNOSES = 'prognoses',
  PROGNOSES_TREND = 'prognoses-trend',
  PROGNOSES_SPECIAL_DAY = 'prognoses-special-day',
  PROGNOSES_DAY = 'prognoses-day',
  PROGNOSES_MONTH = 'prognoses-month',

  PRODUCTS = 'products',
  PRODUCTS_CONFIRMATION = 'products/confirmation',
  PRODUCTS_SYNASTRY = 'products/synastry',
  PRODUCTS_EMOTIONS = 'products/emotions',
  PRODUCTS_SOLAR = 'products/solyar',
  PRODUCTS_PERSON = 'products/person',
  PRODUCTS_SUCCESS = 'products/success',

  SERVICES = 'services',
  PROFFESIONALS = 'professionals',

  MY_DAY = 'my',
  MY_EVENT = 'my/event',

  ARTICLE_TEXT = 'article-text',
  ARTICLE_VIDEO = 'article-video',
  ARTICLE_AUDIO = 'article-audio',

  // Profile
  SUBSCRIPTION_MANAGEMENT = 'profile/subscription_management',
}
