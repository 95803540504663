import { ReactNode, useEffect, useState } from "react"
import styled from "styled-components"
import background from '../../../assets/images/backgrounds/bg_component.jpg';
import { Button } from "src/components/ui/Button/Button";
import { formatNumber } from "src/helpers/numbers";
import { Discount } from "../Products";
import { PartnerLinkTargets } from '../../../libs';


interface NewProductProps {
  icon: ReactNode
  title: string
  description: string
  price: number
  discount: Discount
  onClick:() => void
}

export const NewProduct = (props: NewProductProps) => {
  const {
    icon,
    title,
    description,
    price,
    discount,
    onClick,
  } = props

  const [currentDiscount, setCurrentDiscount] = useState(0)

  useEffect(() => {
      switch (discount.type) {
        case PartnerLinkTargets.ChronosPlus:
        case PartnerLinkTargets.IndiProduct:
          setCurrentDiscount(discount.value)
          break
      }
    }, [discount])

  const finalPrice = Math.round(price * (1 - currentDiscount) + Number.EPSILON)

  return (
    <Container background={background}>
      <Badge>Новинка!</Badge>
      <IconContainer>{icon}</IconContainer>
      <Text>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Text>
      <Price>
        <span className={'oldPrice'}>{currentDiscount > 0 ? `${formatNumber(price)} ₽` : ''}</span>
        <span>{formatNumber(finalPrice)} ₽</span>
      </Price>
      <StyledButton color={'green'} onClick={onClick}>Купить</StyledButton>
    </Container>
  )
}

const Container = styled.div<{background: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;

  position: relative;
  background: ${p => `url(${p.background}) 0 0 / cover no-repeat`};
  border-radius: 1.5rem;
  padding: 2rem 1.5rem;
`

const Badge = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;

	color: var(--color-white);
  background: var(--color-gradus-blue);
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
`

const IconContainer = styled.div`
  margin-top: 2rem;
`

const Text = styled.div`
  text-align: center;
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
`

const Description = styled.div`
  font-size: 0.875rem;
  text-align: center;
  line-height: 21px;
  letter-spacing: -0.4300000071525574px;
  margin-top: 0.5rem;
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-gradus-blue);

  .oldPrice {
    font-size: 1.25rem;
    text-decoration: line-through;
  }
`

const StyledButton = styled(Button)`
  width: fit-content;
  padding: 0.875rem 1rem;
  color: var(--text-primary);
  font-weight: 500;
`
