import styled from "styled-components";
import { ReactComponent as BackSvg } from '../assets/back.svg';
import { ReactComponent as CloseSvg } from '../assets/close.svg';

export default function PersonHeader({
    onBack,
    onClose,
    title
}: {
    onBack: () => void,
    onClose: () => void
    title?: string;
}) {
    return <Container>
        <BackContainer onClick={onBack}><BackSvg /></BackContainer>
        <Title>{title}</Title>
        <CloseContainer onClick={onClose}><CloseSvg /></CloseContainer>
    </Container>
} 

const Container = styled.div`
    display: flex;
    padding: 0.75rem;
    align-items: center;
`

const BackContainer = styled.div`
    cursor: pointer;
    margin-right: 0.5rem;
`

const Title = styled.div`
    margin-right: auto;
    font-size: 0.875rem;
    color: var(--text-third);
`

const CloseContainer = styled.div`
    cursor: pointer; 
`