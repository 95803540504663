import { Indi } from '@chronos/api';
import { observer } from 'mobx-react-lite';
import styled from "styled-components";
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import { searchParams } from '../../../../helpers/url';
import { history } from '../../../../MainRouter';
import { windowsStore } from '../../../../store/Windows';
import { WINDOW_ID_PERSON, WINDOW_ID_PERSON_2 } from '../../helpers/constants';
import { ReactComponent as MoreSvg } from './assets/more.svg';
import { Bookmark } from './components/Bookmark';
import Cell from './components/Cell';
import PersonHeader from './components/PersonHeader';
import { Grid, MoreButton } from './components/PersonMainBlock';
import { hashCode, useScrollToBlock, utfIcon } from './helpers';
import { sendProductGtm } from '../../helpers/gtm';
import store from 'src/store/Store';
import { useEffect, useRef } from 'react';
import PersonFeedback from './components/PersonFeedback';
import { BackButton } from './Person-3';
import { ReactComponent as BackSvg } from './assets/back.svg';


interface IPersonProps {
  data: Indi.GetPerson.IPersonEnergy,
  scrollTo?: string;
  onClickBookmark: (title: string, text: string, isSaved: boolean) => void
  onOpen3Level: (data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => void
}

export default observer(function Person2Energy({data, scrollTo, onClickBookmark, onOpen3Level}: IPersonProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const dataOpen = store.notes.dataOpen

  useEffect(() => {
    store.ui.setContainerRef(containerRef)

    return () => {
      store.ui.setContainerRef(null)
    }
  }, [])

  useEffect(() => {
    if (dataOpen?.screenLevel === 3) {
      const element = containerRef.current?.getElementsByClassName(`cell_${dataOpen?.title}`)[0]

      if (element && element instanceof HTMLElement) {
        element.click()
        store.notes.resetDataOpen()
      }
    }
  }, [dataOpen])

  useScrollToBlock(scrollTo);

  const userId = store.sessionData?.id

  const sp = searchParams();

  const onBack = () => {
    sendProductGtm('PERSON', 'back_header', userId);
    windowsStore.close(WINDOW_ID_PERSON_2)
  }

  const onClose = () => {
    sendProductGtm('PERSON', 'close', userId);
    windowsStore.close(WINDOW_ID_PERSON)
    windowsStore.close(WINDOW_ID_PERSON_2)
    history.push({ pathname: '', search: sp.toString() })
  }

  const onNext = () => {
    sendProductGtm('PERSON', 'next_sphere', userId);
    windowsStore.close(WINDOW_ID_PERSON_2);
    document.getElementById(`relations-more-button`)?.click();
  }

  return (
    <WindowContainer className={'window-container'} id="Person2Window" ref={containerRef}>
      <PersonHeader onBack={onBack} onClose={onClose} title={data.title} />
      <Container>

         <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderIcon>{utfIcon(data.icon)}</MainBlockHeaderIcon>
                <MainBlockHeaderTitle>{data.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>{data.description}</MainBlockText>


        </MainBlock>


         <MainBlock id={`scrollTo${hashCode(data.relax.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.relax.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.relax.description}
            </MainBlockText>

            <div>
                {
                  data.relax.texts!.map((item: any) =>
                    <Bookmark text={item} onClick={(isSaved: boolean) => onClickBookmark(data.relax.title, item, isSaved)}/>
                  )
                }
            </div>
        </MainBlock>



        <MainBlock id={`scrollTo${hashCode(data.energyBlocks.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.energyBlocks.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.energyBlocks.description}
            </MainBlockText>

            <MoreButton onClick={() => onOpen3Level(data.energyBlocks.texts, undefined, 'energy_blocks', userId, 'Здоровье и энергия')}>Подробнее <MoreSvg /></MoreButton>

            <Grid>
                {data.energyBlocks.texts!.map((item: any) =>
                    //@ts-ignore
                    <Cell icon={utfIcon(item.icon)} title={item.title} onClick={() => onOpen3Level(data.energyBlocks.texts, hashCode(item.title), 'energy_blocks', userId, 'Здоровье и энергия')} />
                )}
            </Grid>
        </MainBlock>



        <MainBlock id={`scrollTo${hashCode(data.energyLevels.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.energyLevels.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.energyLevels.description}
            </MainBlockText>

            <div>
                {data.energyLevels.texts!.map((item: any) =>
                    <Bookmark text={item} onClick={(isSaved: boolean) => onClickBookmark(data.energyLevels.title, item, isSaved)}/>
                )}
            </div>
        </MainBlock>



        <MainBlock id={`scrollTo${hashCode(data.zones.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.zones.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.zones.description}
            </MainBlockText>

            <div>
                {data.zones.texts!.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>

        <PersonFeedback />

        <BackButton onClick={onNext} down>
            Перейти к личной жизни
            <BackSvg />
        </BackButton>


      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`

const MainBlock = styled.div`
    margin-bottom: 2rem;
`

const MainBlockHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.675rem;
`

const MainBlockHeaderIcon = styled.div`
    font-size: 1.75rem;
`
const MainBlockHeaderTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 500;

`

const MainBlockText = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
`

const Item = styled.div`
    padding: 0.75rem;
    border-radius: 12px;
    background: rgba(246, 244, 242, 1);
    margin-bottom: 0.25rem;

`
