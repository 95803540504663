import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import store from '../../../../store/Store';
import { WINDOW_ID_PERSON } from '../../helpers/constants';
import { windowsStore } from '../../../../store/Windows';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import PersonHeader from './components/PersonHeader';
import { history } from '../../../../MainRouter';
import { useBackWindow } from '../../../../hooks/router-hooks';
import { ROUTES } from '../../../../helpers/routes';
import { observer } from 'mobx-react-lite';
import { searchParams } from '../../../../helpers/url';
import PersonMainBlock from './components/PersonMainBlock';
import { Indi } from '../../../../libs';
import { sendProductGtm } from '../../helpers/gtm';
import PersonFeedback from './components/PersonFeedback';

interface IPersonProps {
  token: number
}

export const openPerson = (token: number) => windowsStore.open(
  WINDOW_ID_PERSON,
  <Person token={token} />,
  undefined,
  undefined,
  true,
)

export const Person = observer((props: IPersonProps) => {
  const [ data, setData ] = useState<Indi.GetPerson.IPersonDescription>();

  const pathname = `${ROUTES.PRODUCTS_PERSON}/${props.token}`
  useBackWindow(pathname, WINDOW_ID_PERSON);

  const userId = store.sessionData?.id

  document.title = `Продукты • Описание личности`;

  const sp = searchParams()

  const onClose = () => {
    sendProductGtm('PERSON', 'close', userId);
    windowsStore.close(WINDOW_ID_PERSON)
    history.push({ pathname: '', search: sp.toString() })
  }

  useEffect(() => {
    async function getData() {
      const result = await store.person.getPerson(+(props.token || 0));
      setData(result);
    }

    getData();

    store.products.setActiveProductToken(props.token)

    return () => {
      store.products.removeActiveProductToken()
    }
  },  [props.token])

  if(!data) return null;

  return (
    <WindowContainer className={'window-container'}>
      <PersonHeader onBack={onClose} onClose={onClose} />
      <Container>
        {data.personality && <PersonMainBlock type="personality" data={data.personality} allData={data} />}
        {data.finance && <PersonMainBlock type="finance" data={data.finance} allData={data} />}
        {data.energy && <PersonMainBlock type="energy" data={data.energy} allData={data} />}
        {data.relations && <PersonMainBlock type="relations" data={data.relations} allData={data} />}
        {data.purpose && <PersonMainBlock type="purpose" data={data.purpose} allData={data} />}

        <PersonFeedback />
      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`
