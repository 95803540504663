import { observer } from 'mobx-react-lite';
import styled from "styled-components";
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import { searchParams } from '../../../../helpers/url';
import { history } from '../../../../MainRouter';
import { windowsStore } from '../../../../store/Windows';
import { WINDOW_ID_PERSON, WINDOW_ID_PERSON_2 } from '../../helpers/constants';
import { ReactComponent as MoreSvg } from './assets/more.svg';
import { Bookmark } from './components/Bookmark';
import Cell from './components/Cell';
import PersonHeader from './components/PersonHeader';
import { Grid, MoreButton } from './components/PersonMainBlock';
import Range from './components/Range';
import { hashCode, useScrollToBlock, utfIcon } from './helpers';
import { sendProductGtm } from '../../helpers/gtm';
import store from 'src/store/Store';
import { useEffect, useRef } from 'react';
import PersonFeedback from './components/PersonFeedback';
import { BackButton } from './Person-3';
import { ReactComponent as BackSvg } from './assets/back.svg';
import { theme, TType } from './components/InfoBlock';

interface IPersonProps {
  data: any, // api bug,
  scrollTo?: string;
  onClickBookmark: (title: string, text: string, isSaved: boolean) => void
  onOpen3Level: (data: any, hash?: string, gtmEvent?: string, userId?: number, parentTitle?: string, allData?: any) => void
}

export default observer(function Person2Finance({data, scrollTo, onClickBookmark, onOpen3Level}: IPersonProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const dataOpen = store.notes.dataOpen

  useEffect(() => {
    store.ui.setContainerRef(containerRef)

    return () => {
      store.ui.setContainerRef(null)
    }
  }, [])

  useEffect(() => {
    if (dataOpen?.screenLevel === 3) {
      const element = containerRef.current?.getElementsByClassName(`cell_${dataOpen?.title}`)[0]

      if (element && element instanceof HTMLElement) {
        element.click()
        store.notes.resetDataOpen()
      }
    }
  }, [dataOpen])

  useScrollToBlock(scrollTo);

  const userId = store.sessionData?.id

  const sp = searchParams();

  const onBack = () => {
    sendProductGtm('PERSON', 'back_header', userId);
    windowsStore.close(WINDOW_ID_PERSON_2)
  }

  const onClose = () => {
    sendProductGtm('PERSON', 'close', userId);
    windowsStore.close(WINDOW_ID_PERSON)
    windowsStore.close(WINDOW_ID_PERSON_2)
    history.push({ pathname: '', search: sp.toString() })
  }

  const onNext = () => {
    sendProductGtm('PERSON', 'next_sphere', userId);
    windowsStore.close(WINDOW_ID_PERSON_2);
    document.getElementById(`energy-more-button`)?.click();
  }

  const getFormatTypes = () => {
    const types = Object.keys(data.format.types).map(type => ({
      format: type,
      ...data.format.types[type]
    }))

    return types
  }

  return (
    <WindowContainer className={'window-container'} id="Person2Window" ref={containerRef}>
      <PersonHeader onBack={onBack} onClose={onClose} title={data.title} />
      <Container>


        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderIcon>{utfIcon(data.icon)}</MainBlockHeaderIcon>
                <MainBlockHeaderTitle>{data.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>{data.description}</MainBlockText>


        </MainBlock>

        <MainBlock>
            <SubTitle>Тип управления деньгами</SubTitle>
            <MainBlockHeader>
                <MainBlockHeaderIcon>{utfIcon(data.moneyControl.icon)}</MainBlockHeaderIcon>
                <MainBlockHeaderTitle>{data.moneyControl.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.moneyControl.description}
            </MainBlockText>

            <div>
                <Range from={data.scale.metric1} to={data.scale.metric2} position={data.scale.value} color="rgba(105, 181, 236, 1)" />
            </div>
        </MainBlock>


        <MainBlock>
            <SubTitle>Финансовый принцип</SubTitle>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.financePrinciple.result[0].title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.financePrinciple.result[0].description}
            </MainBlockText>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.financePosition.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.financePosition.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.financePosition.description}
            </MainBlockText>

            <MoreButton onClick={() => onOpen3Level(data.financePosition.blocks, undefined, 'finance_career_money_behaviour', userId, 'Финансы и карьера')}>Подробнее <MoreSvg /></MoreButton>

            <Grid>
                {data.financePosition.blocks!.map((item: any, index: number) =>
                    //@ts-ignore
                    <Cell key={`financePosition_${index}`} icon={utfIcon(item.icon)} title={item.title} onClick={() => onOpen3Level(data.financePosition.blocks, hashCode(item.title), 'finance_career_money_behaviour', userId, 'Финансы и карьера')} />
                )}
            </Grid>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.financeRisks.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.financeRisks.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.financeRisks.description}
            </MainBlockText>

            {
              data.financeRisks.blocks.length > 0
                ? <MoreButton onClick={() => onOpen3Level(data.financeRisks.blocks.flat(), undefined, 'finance_career_blocks', userId, 'Финансы и карьера')}>Подробнее <MoreSvg /></MoreButton>
                : <Stub>Нет признаков напряжения или ограничений</Stub>
            }

            <Grid>
                {data.financeRisks.blocks.flat()!.map((item: any, index: number) =>
                    //@ts-ignore
                    <Cell key={`cell_${index}_${item.title}`} icon={utfIcon(item.icon)} title={item.title} onClick={() => onOpen3Level(data.financeRisks.blocks.flat(), hashCode(item.title), 'finance_career_blocks', userId, 'Финансы и карьера')} />
                )}
            </Grid>
        </MainBlock>



        <MainBlock id={`scrollTo${hashCode(data.income.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.income.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.income.description}
            </MainBlockText>

            <div>
                {data.income.result!.map((item: any) =>
                    <Bookmark text={item} onClick={(isSaved: boolean) => onClickBookmark(data.income.title, item, isSaved)}/>
                )}
            </div>
        </MainBlock>


        <MainBlock id={`scrollTo${hashCode(data.format.title)}`}>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.format.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.format.description}
            </MainBlockText>

            <MoreButton onClick={() => onOpen3Level(Object.values(data.format.types), undefined, 'finance_career_employment', userId, 'Финансы и карьера', data.format)}>Подробнее <MoreSvg /></MoreButton>

            <Grid>
                {
                  getFormatTypes().map((item, index) => {
                    return (
                      <Cell
                        key={`cell_${index}_${item.type}`}
                        //@ts-ignore
                        icon={utfIcon(item.icon)} title={item.type}
                        onClick={() => onOpen3Level(Object.values(data.format.types), hashCode(item.type), 'finance_career_employment', userId, 'Финансы и карьера', data.format)}
                        indicatorIcon={theme[data.format.formats[item.format] as TType].icon}
                      />
                    )
                  })
                }
            </Grid>
        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.activity.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.activity.description}
            </MainBlockText>

            <div>
                {data.activity.results!.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>

        <PersonFeedback />

        <BackButton onClick={onNext} down>
            Перейти к здоровью и энергии
            <BackSvg />
        </BackButton>


      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`

const MainBlock = styled.div`
    margin-bottom: 2rem;
`

const MainBlockHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.675rem;
`

const MainBlockHeaderIcon = styled.div`
    font-size: 1.75rem;
`
const MainBlockHeaderTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 500;

`

const MainBlockText = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
`

const Item = styled.div`
    padding: 0.75rem;
    border-radius: 12px;
    background: rgba(246, 244, 242, 1);
    margin-bottom: 0.25rem;

`

const SubTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.375rem;
  color: var(--text-third);
`

const Stub = styled.div`
  padding: 0.75rem;
  border-radius: 12px;
  background: rgba(246, 244, 242, 1);
  margin-bottom: 0.25rem;
`
