import styled, { css } from "styled-components";
import { WINDOW_ID_PERSON, WINDOW_ID_PERSON_2, WINDOW_ID_PERSON_3 } from '../../helpers/constants';
import { windowsStore } from '../../../../store/Windows';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import PersonHeader from './components/PersonHeader';
import { history } from '../../../../MainRouter';
import { observer } from 'mobx-react-lite';
import { searchParams } from '../../../../helpers/url';
import InfoBlock from './components/InfoBlock';
import { Bookmark } from './components/Bookmark';
import { ReactComponent as BackSvg } from './assets/back.svg';
import { hashCode, utfIcon } from "./helpers";
import { useEffect, useRef } from "react";
import { sendProductGtm } from "../../helpers/gtm";
import store from "src/store/Store";

interface IPersonProps {
  data: {
    title: string;
    type?: string;
    icon: string;
    description: string;
    compensatory?: string;
    fix?: string;
    advice?: string;
  }[],
  scrollTo?: string;
  parentTitle?: string;
  allData?: any;
  onClickBookmark: (title: string, text: string, isSaved: boolean) => void;
}

export default observer(function Person3({ data, scrollTo, parentTitle, allData, onClickBookmark }: IPersonProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const userId = store.sessionData?.id

  const sp = searchParams();

  const onBack = () => {
    sendProductGtm('PERSON', 'back_header', userId);
    windowsStore.close(WINDOW_ID_PERSON_3)
  }

  const onBackByFooter = () => {
    sendProductGtm('PERSON', 'back_footer', userId);
    windowsStore.close(WINDOW_ID_PERSON_3)
  }

  const onClose = () => {
    sendProductGtm('PERSON', 'close', userId);
    windowsStore.close(WINDOW_ID_PERSON)
    windowsStore.close(WINDOW_ID_PERSON_2)
    windowsStore.close(WINDOW_ID_PERSON_3)
    history.push({ pathname: '', search: sp.toString() })
  }

  useEffect(() => {
    store.ui.setContainerRef(containerRef)

    return () => {
      store.ui.setContainerRef(null)
    }
  }, [])

  useEffect(() => {
    if(scrollTo) {
      const el = document.getElementById(`scrollTo${scrollTo}`);
      if(el) {
        const w = el.closest('#Person3Window');
        w && w.scrollTo(0, el.getBoundingClientRect().top - 60);
      }

    }
  }, [scrollTo])

  return (
    <WindowContainer className={'window-container'} id="Person3Window" ref={containerRef}>
      <PersonHeader onBack={onBack} onClose={onClose} title={parentTitle} />
      <Container>

        {data.map(item => {

          let bookmarks: any = item.compensatory || item.fix;
          if(bookmarks) {
            if(!Array.isArray(bookmarks)) bookmarks = [bookmarks];
          }

          let indicatorBlock = null;
          if(allData && allData.formats && allData.indicators) {
            let type = null;
            for(let key in allData.types) {
              if(allData.types[key].type === item.type){
                type = key;
              }
            }
            if(type) {
              const indicator = allData.formats[type];
              if(indicator) {
                const text = allData.indicators[indicator];
                indicatorBlock = <InfoBlock type={indicator} text={text} />
              }
            }
          }

          return <Block>
            <ScrollTo id={`scrollTo${hashCode(item.type || item.title)}`} />
            <Icon>{utfIcon(item.icon)}</Icon>
            <Title>{item.type || item.title}</Title>

            {indicatorBlock}

            <Text>{item.description}</Text>

            {bookmarks && <>
              <SubTitle>Как исправить</SubTitle>
              {bookmarks.map((bookmark: string) => { return <Bookmark text={bookmark} onClick={(isSaved: boolean) => onClickBookmark(item.title, bookmark, isSaved)}/>; })}
            </>}

            {item.advice && <>
              <Bookmark text={item.advice} onClick={(isSaved: boolean) => onClickBookmark(item.type || item.title, item.advice!, isSaved)}/>
            </>}

          </Block>
        })}



          {/* <InfoBlock type="yellow" text="Нестыковка с реально подходящей финансовой стратегией" /> */}
{/*
        <Block>
          <Icon>💎</Icon>
          <Title>Масштабные и красивые жесты, которые создают красивую картинку</Title>
          <Text>Эффект “деньги жгут карман”. Это могут быть как простые траты на покупку вещей, предметов, впечатлений, так и инвестиции в интересующие вас направления.</Text>
          <InfoBlock type="red" text="Нестыковка с реально подходящей финансовой стратегией" />
        </Block>

        <Block>
          <Icon>💻</Icon>
          <Title>Фриланс</Title>
          <Text>Эффект “деньги жгут карман”. Это могут быть как простые траты на покупку вещей, предметов, впечатлений, так и инвестиции в интересующие вас направления.</Text>
          <InfoBlock type="green" text="Нестыковка с реально подходящей финансовой стратегией" />
          <SubTitle>Как исправить</SubTitle>
          <Bookmark text="Когда хочется на импульсе потратить или запустить в оборот деньги, возьмите паузу, просчитайте бюджет и риски и вернитесь к этому решению через какое-то время. Если вам это действительно нужно, ваше решение не изменится. " />
        </Block>

        <Block>
          <Icon>💼</Icon>
          <Title>Бизнесс</Title>
          <Text>Этот формат занятости нейтрален для вас. Он может подойти, если нужно поработать временно работать или переждать какой-то период. Но не стоит ожидать, что здесь вы сможете полностью раскрыть свой потенциал.</Text>
          <InfoBlock type="orange" text="Нестыковка с реально подходящей финансовой стратегией" />
        </Block> */}

        <BackButton onClick={onBackByFooter}>
          Вернуться назад
          <BackSvg />
        </BackButton>


      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`


const Block = styled.div`
  margin-bottom: 2rem;
  position: relative;
`

const Icon = styled.div`
  font-size: 1.75rem;
  margin-bottom: 0.75rem;
`

const Title = styled.div`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`

const Text = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
`

const SubTitle = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
`

export const BackButton = styled.div<{down?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 2rem;

  ${p => p.down && css`
    svg {
      transform: rotate(-90deg);
    }
  `}
`

const ScrollTo = styled.div`
  position: absolute;
  visibility: hidden;
  top: -70px;
`
