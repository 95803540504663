import { observer } from "mobx-react-lite";
import { gtmSend } from "src/helpers/url";
import { IProduct } from "src/store/Products";
import store from "src/store/Store";
import styled from "styled-components"
import { PERSON, productsWindowData, WINDOW_ID_CONFIRMATION } from "../helpers/constants";
import { windowsStore } from "src/store/Windows";
import { IProductCode } from "src/store/Payments";
import ProductConfirmation, { openProductConfirmation } from './Confirmation';
import { PersonalityDescriptionIcon } from "../assets/icons";
import { NewProduct } from "./NewProduct";
import ProductCard from './products-card';
import { useCallback, useEffect, useState } from "react";
import { PartnerLinkTargets } from '../../../libs';
import { openEditWindow } from "src/modules/edit/Edit";
import { PersonalPerson } from "src/store/PersonalityDescription";
import { openSmsAuthWindow } from "src/modules/registration/sms";
import { sendProductGtm } from "../helpers/gtm";

export const onClickNewProduct = (product: IProduct) => {
  const userId = store.sessionData?.id
  const isAuth = store.isAuth
  const skipUpdateProfile = !!store.profile.email

  sendProductGtm(product.code, 'products_buy', userId)
  store.products.selectProduct(product)

  const goToConfirmation = () => {
    openProductConfirmation({product: PERSON, step: 2})
  }

  const onSubmit = (data: PersonalPerson) => {
    sendProductGtm(product.code, 'data_done', userId)
    store.personalityDescription.setPerson(data)

    if (isAuth) {
      goToConfirmation()
    } else {
      openSmsAuthWindow(goToConfirmation)
    }
  }

  openEditWindow({
    beforeAuth: !isAuth,
    productPurchase: true,
    skipUpdateProfile,
    onProfileUpdate: (data) => onSubmit(data)
  })
}

export const Catalog = observer(() => {
  const [discount, setDiscount] = useState({ type: '', value: 0 })

  const productsList = store.products.products.filter(product => product.code !== PERSON)
  const newProduct = store.products.products.find(product => product.code === PERSON)
  const partner = store.partner.partner
  const userId = store.sessionData?.id

  const onClickProductCard = (data: IProduct) => {
    store.products.selectProduct(data)

    gtmSend({
      'event': `indi_click-${productsWindowData[data.code].gtm}`,
      'user_id': userId ? userId : ''
    })

    windowsStore.open(
      WINDOW_ID_CONFIRMATION,
      <ProductConfirmation product={data.code as IProductCode} />
    )
  }

  const setPartnerDiscount = useCallback(async () => {
    const targets = [PartnerLinkTargets.ChronosPlus, PartnerLinkTargets.IndiProduct]

    targets.every(async target => {
      const result = await store.partner.setPartnerDiscount(target, partner)

      if (result.partnerDiscount > 0) {
        setDiscount({ type: result.originalTarget?.[0] ?? target, value: result.partnerDiscount })
        return false
      } else {
        return true
      }
    })
  }, [partner])

  useEffect(() => {
    setPartnerDiscount()
  }, [partner, setPartnerDiscount])

  return (
    <Container>
      {
        newProduct && (
          <NewProduct
            icon={<PersonalityDescriptionIcon />}
            title={newProduct.name}
            description={newProduct.description}
            price={newProduct.price.value}
            discount={discount}
            onClick={() => onClickNewProduct(newProduct)}
          />
        )
      }

      <GroupTitle>Другие продукты</GroupTitle>

      {
        productsList.map(product => {
          return (
            <ProductCard
              key={product.id}
              data={product}
              onClick={() => { onClickProductCard(product) }}
              discount={discount}
            />
          )
        })
      }
    </Container>
  )
})

const Container = styled.div`
`

const GroupTitle = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
`
