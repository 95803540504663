import { makeAutoObservable } from "mobx";
import { RefObject } from "react";


export class Ui {
  constructor() {
    makeAutoObservable(this)
  }

  containerRef: RefObject<HTMLDivElement> | null = null

  setContainerRef(containerRef: RefObject<HTMLDivElement> | null) {
    this.containerRef = containerRef
  }
}
