import { useEffect, useRef } from 'react';
import styled from "styled-components";
import store from '../../../../store/Store';
import { WINDOW_ID_PERSON, WINDOW_ID_PERSON_2 } from '../../helpers/constants';
import { windowsStore } from '../../../../store/Windows';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import PersonHeader from './components/PersonHeader';
import { history } from '../../../../MainRouter';
import { observer } from 'mobx-react-lite';
import { searchParams } from '../../../../helpers/url';
import { Indi } from '@chronos/api';
import { utfIcon } from './helpers';
import { Bookmark } from './components/Bookmark';
import { sendProductGtm } from '../../helpers/gtm';
import PersonFeedback from './components/PersonFeedback';
import { BackButton } from './Person-3';
import { ReactComponent as BackSvg } from './assets/back.svg';


interface IPersonProps {
  data: Indi.GetPerson.IPersonPurpose,
  scrollTo?: string;
  onClickBookmark: (title: string, text: string, isSaved: boolean) => void
}

export default observer(function Person2Purpose({data, scrollTo, onClickBookmark}: IPersonProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const dataOpen = store.notes.dataOpen

  useEffect(() => {
    store.ui.setContainerRef(containerRef)

    return () => {
      store.ui.setContainerRef(null)
    }
  }, [])

  useEffect(() => {
    if (dataOpen?.screenLevel === 3) {
      const element = containerRef.current?.getElementsByClassName(`cell_${dataOpen?.title}`)[0]

      if (element && element instanceof HTMLElement) {
        element.click()
        store.notes.resetDataOpen()
      }
    }
  }, [dataOpen])

  const sp = searchParams();

  const userId = store.sessionData?.id

  const onBack = () => {
    sendProductGtm('PERSON', 'back_header', userId);
    windowsStore.close(WINDOW_ID_PERSON_2)
  }

  const onClose = () => {
    sendProductGtm('PERSON', 'close', userId);
    windowsStore.close(WINDOW_ID_PERSON)
    windowsStore.close(WINDOW_ID_PERSON_2)
    history.push({ pathname: '', search: sp.toString() })
  }

  const onNext = () => {
    sendProductGtm('PERSON', 'next_sphere', userId);
    windowsStore.close(WINDOW_ID_PERSON_2);
    document.getElementById(`personality-more-button`)?.click();
  }

  return (
    <WindowContainer className={'window-container'} id="Person2Window" ref={containerRef}>
      <PersonHeader onBack={onBack} onClose={onClose} title={data.title} />
      <Container>


        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderIcon>{utfIcon(data.icon)}</MainBlockHeaderIcon>
                <MainBlockHeaderTitle>{data.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>{data.description}</MainBlockText>


        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.prevExp.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.prevExp.description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data.prevExp.texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>


        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.nextExp.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.nextExp.description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data.nextExp.texts.map((item: any) =>
                  <Bookmark text={item} onClick={(isSaved: boolean) => onClickBookmark(data.nextExp.title, item, isSaved)}/>
                )}
            </div>
        </MainBlock>



        <MainBlock>
            <MainBlockHeader>
                <MainBlockHeaderTitle>{data.partner.title}</MainBlockHeaderTitle>
            </MainBlockHeader>
            <MainBlockText>
                {data.partner.description}
            </MainBlockText>

            <div>
                {
                //@ts-ignore
                data.partner.texts.map((item: any) =>
                    //@ts-ignore
                    <Item>{item}</Item>
                )}
            </div>
        </MainBlock>

        <PersonFeedback />

        <BackButton onClick={onNext} down>
            Перейти к личности
            <BackSvg />
        </BackButton>



      </Container>
    </WindowContainer>
  );
})

const Container = styled(MainPadding)`
`

const MainBlock = styled.div`
    margin-bottom: 2rem;
`

const MainBlockHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.675rem;
`

const MainBlockHeaderIcon = styled.div`
    font-size: 1.75rem;
`
const MainBlockHeaderTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 500;

`

const MainBlockText = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
`

const Item = styled.div`
    padding: 0.75rem;
    border-radius: 12px;
    background: rgba(246, 244, 242, 1);
    margin-bottom: 0.25rem;

`
