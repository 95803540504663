
import React from 'react';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react-lite';
import { Route, Router, Switch } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import App from './App';
import { useRouter } from './hooks/router-hooks';
import { servicesRoutes } from './modules/consultations/Consultations';
import TinkoffPayed from './modules/consultations/TinkoffPayed';
import store from './store/Store';
import { FinanceCapacityPage } from './modules/external/finance-capacity';
import { NatalPage } from './modules/external/natal';


export const history = createBrowserHistory();
export const ymID = 49958389;

export default observer(function MainRouter() {
  useRouter();

  const ymOptions = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: "dataLayer",
    userParams: {
      UserID: store.profile.id,
      beta: false,
      partnerParams: store.partner.getPartnerParams()
    }
  }

  return <>
    <YMInitializer
      accounts={[ymID]}
      options={ymOptions}
    />

    <Router history={history}>
      <Switch>
        <Route path="/finance_capacity/:id" exact component={FinanceCapacityPage} />
        <Route path="/natal/:id" exact component={NatalPage} />
				<Route path={servicesRoutes.TinkoffPayed.path} exact component={TinkoffPayed}/>
				<Route path="*" exact component={App}/>
      </Switch>
    </Router>
  </>
})
