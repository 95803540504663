import dayjs from "dayjs"
import { observer } from "mobx-react-lite"
import { getArticleWord } from "src/helpers/words"
import { getPinnedGroups } from "src/store/localStorageHelpers"
import { INote } from "src/store/Notes"
import styled, { css } from "styled-components"
import { NotesGroup } from "./NotesGroup"
import { ChevronDownIcon } from "src/assets/icons/system/system-icons"
import { useState } from "react"


interface EventsNotesProps {
  notes: INote[]
  goToEvent: (note: INote) => void
}

const words = ['рекомендация', 'рекомендации', 'рекомендаций']

export const EventsNotes = observer((props: EventsNotesProps) => {
  const {
    notes,
    goToEvent,
  } = props

    const [showNotes, setShowNotes] = useState(true)

  const pinnedGroups = getPinnedGroups()

  const notesGroups = notes.reduce((acc, next) => {
    const spheres = { ...acc }

    if (!next.sphereName) return spheres

    spheres[next.sphereName] = spheres[next.sphereName] || []

    spheres[next.sphereName].push(next)

    spheres[next.sphereName].sort((sphere1, sphere2) => dayjs(sphere1.to).diff(dayjs()) - dayjs(sphere2.to).diff(dayjs()))

    return spheres
  }, {} as Record<string, INote[]>)

  const spheres = notes.reduce((acc, next) => {
    const spheres = [...acc]

    if (!next.sphereName) return spheres

    if (!spheres.includes(next.sphereName)) {
      spheres.push(next.sphereName)
    }

    return spheres
  }, [] as string[])
    .sort((sphere1, sphere2) => pinnedGroups.indexOf(sphere2) - pinnedGroups.indexOf(sphere1))

  const getCounter = () => {
    const count = notes.reduce((acc, next) => {
      if (next.sphereName) {
        return acc + next.recommendations.length
      } else {
        return acc
      }
    }, 0)

    return `${count} ${getArticleWord(words, count)}`
  }

  const toggleShowNotes = () => {
    setShowNotes(!showNotes)
  }

  if (spheres.length === 0) return null

  return (
    <Container>
      <Header>
        <div>
          <Title>Персональный прогноз</Title>
          <Counter>{getCounter()}</Counter>
        </div>

        <IconContainer
          showNotes={showNotes}
          onClick={toggleShowNotes}
        >
          <ChevronDownIcon/>
        </IconContainer>
      </Header>

      <Content showNotes={showNotes}>
        {
          spheres.map((sphere, index) => (
            <NotesGroup
              key={`eventNotes_notes_group_${sphere}_${index}`}
              notes={notesGroups[sphere]}
              sphere={sphere}
              goToEvent={(note) => goToEvent(note)}
              setShowToaster={value => { }}
            />
          ))
        }
      </Content>
    </Container>
  )
})

const Container = styled.div`
  margin-bottom: 1.5rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
`

const Counter = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--text-third);
  margin-top: 0.5rem;
`

const IconContainer = styled.div<{showNotes: boolean}>`
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-white);
  border-radius: 50%;
  box-shadow: 0px 0.75px 2px 0px #8992AC1F;
  box-shadow: 0px 4px 14px 0px #81889D14;

  ${p => p.showNotes && css`
    svg {
      transform: rotate(180deg);
    }
  `}
`

const Content = styled.div<{showNotes: boolean}>`
  margin-top: 1.25rem;
  display: ${p => p.showNotes ? 'block' : 'none'};
`
