import styled from "styled-components"
import { GiftIcon } from "../../../../assets/icons/system/system-icons"
import { Background, Offer } from "../../../../components/Offer"

export const PersonalityOffer = () => {
  return (
    <Container>
      <StyledOffer
        icon={<GiftIcon/>}
        title='Полный доступ к Chronos на 1 месяц в подарок'
        text='Опробуйте ежедневный персональный прогноз в вашем телефоне бесплатно!'
        background={Background.PINK}
      />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  bottom: -65%;
  z-index: -1;
`

const StyledOffer = styled(Offer)`
  padding-top: 3rem;
  padding-bottom: 1.25rem;

  .title {
    font-size: 1.25rem;

    svg {
      width: 4rem;
      height: 3rem;
    }
  }

  .text {
    margin-bottom: 0;
  }
`
