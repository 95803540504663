import styled from "styled-components";
import { ReactComponent as BookmarkIcon } from '../assets/bookmark.svg';
import { ReactComponent as BookmarkFillIcon } from '../assets/bookmark-fill.svg';
import { useEffect, useState } from "react";
import { sendProductGtm } from "src/modules/products/helpers/gtm";
import store from "src/store/Store";
import { observer } from "mobx-react-lite";

export const Bookmark = observer(({
    text,
    onClick,
}: {
    text: string
    onClick: (isSaved: boolean) => void
}) => {
    const [isFilled, setIsFilled] = useState(false);

    const userId = store.sessionData?.id

    useEffect(() => {
      const note = store.notes.notesList.find(note => {
        let result = false

        for (const rec of note.recommendations) {
          if (rec.text === text) {
            result = true
            break
          }
        }

        return result
      })

      if (note) setIsFilled(true)
    }, [text])

    const onClickHandler = () => {
      onClick(isFilled)
      setIsFilled(!isFilled)
    }

    return <Container>
      <Text>{text}</Text>
      <div onClick={onClickHandler}>
        {isFilled ? <BookmarkFillIcon /> : <BookmarkIcon />}
      </div>
    </Container>
})

const Container = styled.div`
    border-radius: 12px;
    padding: 0.75rem;
    border: 1px solid rgba(0, 0, 0, 0.04);
    display: flex;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
    background: rgba(165, 232, 97, 0.1);

    svg {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
    }
`

const Text = styled.div`
    font-size: 1rem;
    line-height: 1.5rem;
`
