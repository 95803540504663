import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { MainPadding, WindowContainer } from "../../components/layout/elements";
import { toISOString } from "../../helpers/dates";
import { gtmSend } from "../../helpers/url";
import { useBackWindow } from "../../hooks/router-hooks";
import { Indi } from "../../libs";
import { INote } from "../../store/Notes";
import { popupsStore } from "../../store/Popups";
import store from "../../store/Store";
import { windowsStore } from "../../store/Windows";
import MyEventsScrollView, { WINDOW_ID_MYDAY_SCROLL_VIEW } from "../my-day/components/MyEventsScrollView";
import { WindowHeader } from "../windows/components/WindowHeader";
import { EmptyNotes } from "./components/EmptyNotes";
import { EventsNotes } from "./components/EventsNotes/EventsNotes";
import { IndiProductCode } from "../../libs"
import { ProductsNotes } from "./components/ProductsNotes/ProductsNotes";


export const WINDOW_ID_NOTES: string = 'WINDOW_ID_NOTES';

export const openNotes = (backToEvents?: boolean, showOnClose?: boolean) => {
  windowsStore.open(
    WINDOW_ID_NOTES,
    <Notes backToEvents={backToEvents} showOnClose={showOnClose}/>,
  )
}

interface NotesProps {
  backToEvents?: boolean
  showOnClose?: boolean
}

export const Notes = observer((props: NotesProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useBackWindow('', WINDOW_ID_NOTES)

  const userId = store.sessionData?.id
  const notes = store.notes.notesList
  const events = store.events.events

  useEffect(() => {
    gtmSend({'event': 'ind_prognosis_note_entry', 'user_id': userId ? userId : ''})

    return () => {
      if (props.backToEvents) {
        popupsStore.decreaseZIndex()
      }
    }
  }, [])

  useEffect(() => {
    if (userId) {
      store.notes.buildNotes(userId)
    }
  }, [userId])

  const goToEvent = (note?: INote) => {
    const openEvent = (events: Indi.Events.Event[], event: Indi.Events.Event) =>  {
      windowsStore.open(
        WINDOW_ID_MYDAY_SCROLL_VIEW,
        <MyEventsScrollView list={events} currentId={event.id} />,
        'fade',
        true,
        false,
        true
      )
    }

    if (note) {
      for (let key in events) {
        const event = events[key].events.find(event => event.title === note.title && event.startDate === note.from && event.endDate === note.to)

        if (events[key].events.length > 0 && event) {
          openEvent(events[key].events, event)
          break
        }
      }
    } else {
      const day = toISOString(dayjs().startOf('day'))
      const event = events[day].events.find(event => event.recommendations.length > 0)

      if (event) {
        openEvent(events[day].events, event)
      }
    }
  }

  const onClose = () => windowsStore.close(WINDOW_ID_NOTES)

  return (
    <WindowContainer ref={containerRef}>
      <WindowHeader title='Мои заметки' onBack={props.showOnClose ? onClose : undefined}/>
      <NotesContainer isEmpty={notes.length === 0}>

        {
          notes.length > 0
            ? <>
                <EventsNotes notes={notes} goToEvent={(note: INote) => goToEvent(note)}/>
                <ProductsNotes notes={notes} product={IndiProductCode.PERSON}/>
              </>
            : <EmptyNotes goToEvent={goToEvent}/>
        }
      </NotesContainer>
    </WindowContainer>
  )
})

const NotesContainer = styled(MainPadding)<{isEmpty: boolean}>`
  display: flex;
  flex-direction: column;

  padding-bottom: 8rem;

  ${p => p.isEmpty && css`
    justify-content: center;
    align-items: center;

    min-height: 70vh;
    padding-bottom: 1rem;
  `}
`
