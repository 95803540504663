import { ReactNode } from "react"
import styled, { css } from "styled-components"

interface ToasterProps {
  className?: string
  title: string
  btnTitle?: string
  icon?: ReactNode
  onClick?(): void
  color?: string
  background?: string
}

export const Toaster = (props: ToasterProps) => {
  const {
    className = '',
    title,
    btnTitle,
    icon,
    onClick,
    color,
    background,
  } = props

  return (
    <Container
      className={className}
      onClick={onClick}
      background={background}
    >
      <div className="left">
        {icon && icon}
        <div>{title}</div>
      </div>
      {btnTitle && onClick && <Button color={color}>{btnTitle}</Button>}
    </Container>
  )
}

const Container = styled.div<{background?: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  bottom: 3rem;

  color: var(--color-white);
  font-size: 0.875rem;
  font-weight: 300;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  z-index: var(--z-index-modal);
  background: ${p => p.background ?? '#222222'};
  max-width: 380px;

  .left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`
const Button = styled.div<{color?: string}>`
  ${p => p.color && css`
    color: ${p.color};
  `}
`
