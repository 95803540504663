import styled from "styled-components"
import { SystemSwitcher } from "./ui/SistemSwitcher"

interface SwitcherItemProps {
  className?: string
  title: string
  subtitle: string
  value: boolean | undefined
  onChange(): void
}

export const SwitcherItem = (props: SwitcherItemProps) => {
  const {
    className,
    title,
    subtitle,
    value,
    onChange,
  } = props

  return (
    <Container className={className}>
      <Description>
        <div className={'title'}>{title}</div>
        <div className={'subtitle'}>{subtitle}</div>
      </Description>

      <SystemSwitcher
        value={value}
        onChange={onChange}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Description = styled.div`
  .title {
    font-size: 1rem;
    font-weight: 500;
  }

  .subtitle {
    font-size: 0.875rem;
    color: var(--text-third);
    margin-top: 1rem;
  }
`
