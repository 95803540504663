import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import store from '../../store/Store';

import Day from './components/Day';
import Calendar from './components/Calendar';
import Trend from './components/Trend';
import { getStartDateFromUrl } from 'src/helpers/dates';
import { isTester } from 'src/helpers/autotests';

// Текущий GMT пользователя
const dateNow = new Date();
export const gmtLocal = (dateNow.getTimezoneOffset() / -60);

interface IPinnedComponents {
  component: React.JSXElementConstructor<any>,
  name: string;
  props: any;
}

export default observer(function PrognosesDaily() {
  const email = store.profile.email || ''

  const getDay= () => {
    if (isTester(email)) {
      return getStartDateFromUrl()
    } else {
      return dayjs().local()
    }
  }

  const [currentDay, setCurrentDay] = useState(getDay());
  const [pinned, setPinned] = useState<any>([]);

  useEffect(() => {
    store.prognoses.getPrognosesFeed(currentDay.toISOString(), gmtLocal);
  }, [currentDay]);

  const getTrendsData = () => {
    document.title = `Общий прогноз • ${dayjs(currentDay).locale('ru').format('D MMMM')}`;
    return store.prognoses.getTrends(currentDay);
  }

  const getSortedComponents = () => {
    const dayData = store.prognoses.getDay(currentDay)

    if(!dayData?.id) {
      return [];
    }

    const components: IPinnedComponents[] = [{
      component: Day,
      name: 'day',
      props: {
        id: dayData.id,
      },
    }];

    getTrendsData()?.forEach(trend => {
      components.push({
        component: Trend,
        name: `trend-${trend.id}`,
        props: {
          id: trend.id,
        },
      });
    });

    return components.sort((c1, c2) => {
      const i1 = pinned.indexOf(c1.name);
      const i2 = pinned.indexOf(c2.name);
      return i2 - i1;
    })
  }

  const togglePin = (name: string) => {
    if(pinned.indexOf(name) >= 0) {
      setPinned(pinned.filter((p: string) => p !== name));
    } else {
      setPinned(pinned.concat(name).slice(-2));
    }
  }

  if(!store.prognoses.prognoses) {
    return null;
  }

  return (
    <Container>
      <Calendar day={currentDay} setDay={setCurrentDay} />
      { getSortedComponents().map((c, i: number) => (
          <c.component
            key={`prognoses_${i}`}
            {...c.props}
            togglePin={() => togglePin(c.name)}
            isPinned={pinned.indexOf(c.name) >= 0}
          />
      ))}
    </Container>
  )
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 3.5rem;
`;
