import { IOption } from "../../../components/ui/ButtonSwitcher";

// export const SECTION_CATALOG = 'catalog';
// export const SECTION_BOUGHT = 'bought';

export enum PRODUCTS_SECTION {
  CATALOG = 'catalog',
  PURCHASED = 'purchased'
}


export const sectionsOptions: IOption[] = [
  {
    value: PRODUCTS_SECTION.CATALOG,
    label: 'Каталог'
  },
  {
    value: PRODUCTS_SECTION.PURCHASED,
    label: 'Купленные'
  }
];

export const SYNASTRY = 'SYNASTRY'
export const WINDOW_ID_SYNASTRY: string = 'WINDOW_ID_SYNASTRY'

export const EMOTIONS = 'EMOTIONS'
export const WINDOW_ID_EMOTIONS: string = 'WINDOW_ID_EMOTIONS';

export const SOLAR = 'SOLAR'
export const WINDOW_ID_SOLAR: string = 'WINDOW_ID_SOLAR';

export const PERSON = 'PERSON'
export const WINDOW_ID_PERSON: string = 'WINDOW_ID_PERSON';
export const WINDOW_ID_PERSON_2: string = 'WINDOW_ID_PERSON_2';
export const WINDOW_ID_PERSON_3: string = 'WINDOW_ID_PERSON_3';

export const WINDOW_ID_CONFIRMATION: string = 'WINDOW_ID_CONFIRMATION'
export const WINDOW_ID_SUCCESS_PAGE: string = 'WINDOW_ID_SUCCESS_PAGE'

export type PRODUCTS = typeof SYNASTRY | typeof EMOTIONS | typeof SOLAR

type ProductsWindowType = {
  purchased: string
  gtm: string
}

export const productsWindowData: {
  [key: string]: ProductsWindowType;
} = {
  SYNASTRY: {
    purchased: WINDOW_ID_SYNASTRY,
    gtm: SYNASTRY.toLocaleLowerCase(),
  },
  EMOTIONS: {
    purchased: WINDOW_ID_EMOTIONS,
    gtm: EMOTIONS.toLocaleLowerCase(),
  },
  SOLAR: {
    purchased: WINDOW_ID_SOLAR,
    gtm: SOLAR.toLocaleLowerCase(),
  },
  PERSON: {
    purchased: WINDOW_ID_PERSON,
    gtm: PERSON.toLocaleLowerCase(),
  },
}

export enum ProductTokens {
  SYNASTRY = 1,
  EMOTIONS = 2,
  SOLAR = 3,
  PERSON = 4,
}
