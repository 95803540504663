import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { CALENDAR_IS_OPENED_CLASS } from 'src/components/calendar/Сalendar';
import styled from 'styled-components';
import { GridContainer } from '../../../../components/layout/elements';
import { toISOString } from '../../../../helpers/dates';
import { gtmSend } from '../../../../helpers/url';
import { useMoveShutter } from '../../../../hooks/popup-hooks';
import { useHorizontalSwipes } from '../../../../hooks/swipe-hooks';
import store from '../../../../store/Store';
import { gmtLocal } from '../../../prognoses/prognoses-daily';
import { ActiveSubscriptionScreenItem } from './main-page-active-subscription-screen-item';
import { openSubscribeBanner } from 'src/modules/subscription/SubscribeBanner/SubscribeBanner';


interface MainPageActiveSubscriptionScreenProps {
	selectedDay: dayjs.Dayjs
	setSelectedDay(day: dayjs.Dayjs): void
  scrolled: boolean
  setScrolled(value: boolean): void
  newCounterRef: React.RefObject<HTMLDivElement>
}

export const MainPageActiveSubscriptionScreen = observer((props: MainPageActiveSubscriptionScreenProps) => {
	const {
		selectedDay,
		setSelectedDay,
		scrolled,
		setScrolled,
    newCounterRef,
	} = props

  const [ x, setX ] = useState(-100)
  const [ firstRender, setFirstRender ] = useState(true)

  const innerContainerRef = useRef<HTMLDivElement | null>(null)
  const todoRef = useRef<HTMLDivElement | null>(null)
  const gtmSendRef = useRef(true)

  const userId = store.sessionData?.id
  const shutterRef = store.shutter.shutterRef
  const blockChangeDate = store.sessionData?.indi?.isCardlessTrial

  const minPopupPosition = window.screen.height > 700 ? 73 : 77
  // const minPopupPosition = detectIphone12ProMax() ? -140 : -100
  const maxPopupPosition = window.screen.height > 700 ? 60 : 63
  // const maxPopupPosition = detectIphone12ProMax() ? -180 : -140

  const eventsItems = [
    {
      events: store.events.events[toISOString(selectedDay.add(-1, 'day').startOf('day'))],
      day: toISOString(selectedDay.add(-1, 'day').startOf('day'))
    },
    {
      events: store.events.events[toISOString(selectedDay.startOf('day'))],
      day: toISOString(selectedDay.startOf('day'))
    },
    {
      events: store.events.events[toISOString(selectedDay.add(1, 'day').startOf('day'))],
      day: toISOString(selectedDay.add(1, 'day').startOf('day'))
    }
  ]

  const onSwipe = (step: number) => {
    if (blockChangeDate) {
      openSubscribeBanner()
      return
    }

    if (document.querySelector(`.${CALENDAR_IS_OPENED_CLASS}`)) return

    setX(x => Math.min(Math.max(x + step, -200), 0))

    setTimeout(() => {
      const dayDiff = step > 0 ? -1: 1;
      setSelectedDay(selectedDay.add(dayDiff, 'day'))

      innerContainerRef.current && innerContainerRef.current.classList.remove('smooth')
      setX(-100);
      setTimeout(() => innerContainerRef.current && innerContainerRef.current.classList.add('smooth'), 100);

    }, 300)

    gtmSend({ 'event': 'indi_main_cange-date_swipe', 'user_id': userId ? userId : '' })
  }

  const onSwipedLeft = () => onSwipe(-100)
  const onSwipedRight = () => onSwipe(+100)

  const { swipeHandlers } = useHorizontalSwipes<HTMLDivElement >(onSwipedLeft, onSwipedRight)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const refPassthrough = (el: HTMLDivElement) => {
    swipeHandlers.ref(el);
    containerRef.current = el;
  }

  const getEvents = async (selectedDay: dayjs.Dayjs) => {
    await store.events.loadEventsByDate(selectedDay.add(-1, 'day'), selectedDay.add(1, 'day'))
  }

  useMoveShutter(containerRef, shutterRef)

  useEffect(() => {
    const send = () => {

      const top = todoRef.current?.getBoundingClientRect().top

      if (gtmSendRef.current && top && top < 300) {
        gtmSend({'event': 'ind_prognosis_main-page_daily-recommendations-shown', 'user_id': userId ? userId : ''})
        gtmSendRef.current = false
      }
    }

    containerRef.current?.addEventListener('scroll', send)

    return () => {
      containerRef.current?.removeEventListener('scroll', send)
    }
  }, [])

  useEffect(() =>  {
    if (firstRender) {
      innerContainerRef.current && innerContainerRef.current.classList.remove('smooth')
      setFirstRender(false)
      setTimeout(() => innerContainerRef.current && innerContainerRef.current.classList.add('smooth'), 100)
    }

    innerContainerRef.current && (innerContainerRef.current.style.transform = `translateX(${x}%)`)
  }, [x, innerContainerRef, firstRender])

  useEffect(() => {
    getEvents(selectedDay)
    gtmSendRef.current = true;

    if (!store.prognoses.getDay(selectedDay)) {
      store.prognoses.getPrognosesFeed(selectedDay.toISOString(), gmtLocal)
    }
  }, [selectedDay]);

  useEffect(() => {
    const checkScroll = () => {
      const containerIsScrolled = innerContainerRef.current?.getBoundingClientRect().top !== 0

      if (containerIsScrolled !== scrolled) {
        setScrolled(containerIsScrolled)

        if (newCounterRef.current) {
          newCounterRef.current.style.display = 'none'
        }
      }
    }

    containerRef.current?.addEventListener('scroll', checkScroll)

    return () => {
      containerRef.current?.removeEventListener('scroll', checkScroll)
    }
  }, [swipeHandlers, scrolled])

  return (
    <Container
      {...swipeHandlers}
      ref={refPassthrough}
      id={'main-page-active-subscription-screen'}
    >
      <InnerContainer
        id={'main-page-active-subscription-screen-inner-containerRef'}
        ref={innerContainerRef}
        className='smooth'>
        {
          eventsItems.map((item, index) => (
            <ActiveSubscriptionScreenItem
              key={item.day}
              index={index}
              events={item.events}
              day={item.day}
              todoRef={todoRef}
            />
          ))
        }
      </InnerContainer>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`
const InnerContainer = styled(GridContainer)`
  width: 100%;
  height: 100%;
  grid-template-columns: 100% 100% 100%;

  &.smooth {
    transition: transform 0.3s;
  }
`
