import styled from "styled-components"
import { ReactComponent as CloseSvg } from './close.svg';
import { ReactComponent as DataSvg } from './data.svg';
import { ReactComponent as InfoSvg } from './info.svg';
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Coins1Path from './coins-1.png';
import Coins2Path from './coins-2.png';
import BannerPath from './banner.png';
import { gtmSend } from "src/helpers/url";

interface IRouterProps {
    id: string;
}
interface IFinanceCapacityProps extends RouteComponentProps<IRouterProps> {
}

export function FinanceCapacityPage(props: IFinanceCapacityProps) {
    const [allData, setData] = useState<any>(null);

    const id = props.match.params.id;

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND}/api/finance-capacity-telegram/${id}`)
        .then(data => data.json())
        .then(data => {
            document.body.style.pointerEvents = 'initial';
            setData(data.data)
        })
    }, [id])

    if(!allData) return <></>;

    const user = allData.user_data;
    const data = allData.fin_data;

    const goToTrial = (e: any) => {
        e.preventDefault();
        gtmSend({'event': `indi_freefincap_ad_14days`, 'user_id': ''})
        setTimeout(() => {
            window.location.href = `https://indi.chronos.mg/trial?d=14&${window.location.search.slice(1)}`;
        }, 500)
    }

    return <Body>
        <Container>
            <Header>
                <TopHeader>
                    <Info>
                        <Name>Здравствуйте, {user.name}</Name>
                        <Date>{user.date}, {user.time}, {user.place} </Date>
                    </Info>
                    <Close href="/"><CloseSvg /></Close>
                </TopHeader>
                <BottomHeader>
                    <span>Ваша финансовая емкость</span>
                    {
                        data.FC.length > 7 ?
                        <>
                        <SumFirst>{data.FC} ₽</SumFirst>
                        <SumSecond>в месяц</SumSecond>
                        </> :
                        <SumFirst>{data.FC} ₽ в месяц</SumFirst>
                    }
                </BottomHeader>
                <Img1 src={Coins1Path} />
                <Img2 src={Coins2Path} />
            </Header>

            <Content>
                <Text>
                    Наш высокотехнологичный астропроцессор анализирует натальную карту и выдает значение вашего финансового потенциала, исключая ошибку из-за человеческого фактора. <br /><br />
                    Финансовая емкость - это тот потенциал, который заложен в вашу финансовую сферу, это конкретная сумма, которую вы потенциально можете зарабатывать, работая в найме, проектах или будучи фрилансером.
                </Text>
                <Data>
                    <DataSvg />
                    <div dangerouslySetInnerHTML={{__html: data.sourceDetail}} />
                </Data>
                <Text>
                    <strong>Указанная сумма не является вашим стабильным ежемесячным доходом?</strong><br /><br />
                    Если указанная сумма еще не является вашим стабильным ежемесячным доходом, то рекомендуем проконсультироваться с астрологом, который даст рекомендации, как вам раскрыть свой финансовый потенциал на 100%.<br /><br />
                    Если вы уже зарабатываете указанную сумму или даже больше, то на консультации вы сможете обсудить со специалистом, какие другие источники дохода вы можете найти для увеличения своего финансового благосостояния.
                </Text>
                <Text>
                    <strong>Обратите внимание входите ли вы в одну из категорий</strong><br /><br />
                    <Category>Владелец собственного бизнеса</Category>
                    <Category>Удачно вложили капитал и получаете дивиденды</Category>
                    <Category>Выиграли в лотерею</Category>
                    <Category>Получили крупную премию</Category>
                    <Category>Сделали открытие и получаете выплаты за него</Category>
                    <Category>Получаете прочие сверхдоходы</Category>
                    <Information>
                        <div><InfoSvg /></div>
                        <div>Если вы нашли себя здесь, то цифра может быть неактуальна. В этом случае рекомендуем обратиться к профессиональному астрологу, который поможет вам разработать персональную финансовую стратегию после глубокого изучения вашей натальной карты.</div>
                    </Information>
                </Text>
                <Text>
                    <strong>Отслеживайте изменения в сфере финансов </strong><br /><br />
                    В приложении Chronos Plus подскажем, как компенсировать возможные риски, когда лучше сберегать доход или менять стратегию заработка
                </Text>
                <a href="/" onClick={goToTrial}><Banner src={BannerPath} /></a>
            </Content>
        </Container>
    </Body>
}

const Body = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    background: radial-gradient(370.13% 86.01% at -19.62% 2.65%, #FFF9EF 0%, #FFF0EA 21.82%, #FFF0F4 37.82%, #F2E5FF 66.82%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;;
`

const Container = styled.div`
    width: 100%;
    color: var(--text-secondary);
    font-size: 1rem;

    @media (min-width: 400px) {
       max-width: 450px;
    }

    strong {
        font-size: 1.125rem;
        color: var(--text-primary);
    }
`

const Category = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2.5rem;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    padding: 0.625rem;
`

const Information = styled.div`
    padding: 0.75rem;
    background: rgba(239, 178, 47, 0.1);
    border-radius: 1rem;
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;

     svg {
        display:block;
        width: 1.5rem;
    }
`

const Content = styled.div`
    background: white;
    padding: 1.5rem 1rem 2rem;
    line-height: 1.5rem;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
`

const Text = styled.div`
    padding-right: 1rem;
    margin-bottom: 1.5rem;
`

const Data = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.04);
    background: rgba(205, 160, 225, 0.1);
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1.5rem;

    > svg {
        margin-bottom: 1rem;
    }

`

const Banner = styled.img`
    width: 100%;
    margin-bottom: 3rem;
`

const Header = styled.div`
    padding: 1rem 1rem 1.5rem;
    position: relative;
`

const TopHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
`

const Info = styled.div`
    flex: 1;
`

const Close = styled.a`
    cursor: pointer;
`

const Name = styled.div`
    margin-bottom: 0.375rem;
`

const Date = styled.div`
    font-size: 0.875rem;
    color: var(--text-third);
`

const BottomHeader = styled.div`
    
`

const SumFirst = styled.div`
    font-size: 2rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-top: 0.5rem;
`

const SumSecond = styled.div`
    font-size: 1.375rem;
    font-weight: 600;
    color: var(--text-primary);
`

const Img1 = styled.img`
    position: absolute;
    right: -0.6rem;
    bottom: 2.1rem;
    width: 5rem;
`

const Img2 = styled.img`
    position: absolute;
    right: 1rem;
    bottom: 0;
    width: 5.5rem;
`