import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { IAuthIndi } from 'src/store/Auth';
import styled from 'styled-components';
import bgPath from '../../assets/images/purple-bg.jpg';
import { WindowContainer } from '../../components/layout/elements';
import { Button } from '../../components/ui/Button/Button';
import { ROUTES } from '../../helpers/routes';
import { gtmSend } from '../../helpers/url';
import { useBackWindow } from '../../hooks/router-hooks';
import { Indi, searchParams } from '../../libs';
import { history } from '../../MainRouter';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { openEditWindow } from '../edit/Edit';
import { FreePeriod } from '../payment/helpers/tariffs';
import { Payment, WINDOW_ID_PAYMENT } from '../payment/Payment';
import { openSmsAuthWindow } from '../registration/sms/index';
import { closePaywall, PaywallClose } from './components/Close/PaywallClose';
import Slider from './components/Slider';
import { slides } from './helpers/sliders';
import { isHasSubscriptionOrTrial } from './helpers/tariffs';
import { openPaymentWithoutCard } from '../payment/components/payment-without-card';


export const WINDOW_ID_PAYWALL: string = 'WINDOW_ID_PAYWALL';

export const openPaywallWindow = (freePeriod?: FreePeriod) => {
  windowsStore.open(
    WINDOW_ID_PAYWALL,
    <Paywall freePeriod={freePeriod} />,
    'fade'
  )
}

export const openPaymentWindow = (selectedTariff: Indi.indiGetTariffs.Tariff, options: {
  freePeriod?: FreePeriod,
  trialPeriod?: number,
  direct?: boolean,
}) => {
  windowsStore.open(
    WINDOW_ID_PAYMENT,
    (() => (
        <Payment
          selectedTariff={selectedTariff}
          freePeriod={options.freePeriod}
          trialPeriod={options.trialPeriod}
          direct={options.direct}
        />
      )
    )()
  )
}

export const openSmsRegistrationWindow = (
  onSuccess?: (channel: string, sessionData?: IAuthIndi) => void,
  direct?: boolean,
) => {
  openSmsAuthWindow(
    onSuccess,
    undefined,
    undefined,
    undefined,
    undefined,
    direct,
  )
}

interface PaywallProps {
  freePeriod?: FreePeriod
}

export const Paywall = observer((props: PaywallProps) => {
  useBackWindow(ROUTES.PAYWALL, WINDOW_ID_PAYWALL);

  const tariffs = store.events.tariffs;
  const userId = store.sessionData?.id || '';
  const isAuth = store.isAuth;
  const info = store.sessionData?.indi;
  const [ tariff, setTariff ] = useState<Indi.indiGetTariffs.Tariff | undefined>();
	const [ activeSlide, setActiveSlide ] = useState(0)

  const sp = searchParams();
  const period = Number(sp.get('d'));
  const trialPeriod = [3,14].includes(period) ? period : 7;

  const onAuthSuccess = (channel: string, sessionData?: IAuthIndi) => {
    if (!sessionData) return

    const hasSubscriptionOrTrial = isHasSubscriptionOrTrial(sessionData);

    if (!hasSubscriptionOrTrial) {
      // The payment window was Changed. Task 6374.
      // openPaymentWindow(tariff!, {
      //   freePeriod: props.freePeriod ? props.freePeriod : FreePeriod.TRIAL,
      //   trialPeriod
      // });

      openPaymentWithoutCard()
    }
  }

  useEffect(() => {
    store.pwa.hideAppInstallPopup()
  }, [])

  useEffect(() => {
    const usedTrial = (info?.trialExpiryDate && dayjs().isAfter(info?.trialExpiryDate)) || info?.cardLastFour

    // tmp

    // if (tariff) {
    //   openPaymentWindow(tariff, {
    //     freePeriod: props.freePeriod ? props.freePeriod : FreePeriod.TRIAL,
    //     trialPeriod
    //   });
    // }

    // tmp

		if (usedTrial) {
			closePaywall();
			window.location.href.includes('trial') && history.push('/')
		}

    if (usedTrial && tariffs.length > 0) {
      const tariff6 = tariffs.find(item => item.period === 6)!;
      openPaymentWindow(tariff6, {});
    }
  }, [info, tariffs])

	useEffect(() => {
    let event

    if (activeSlide === 0) {
      const event = {'event': 'indi_chronos-plus-stories_visit_all', 'userId': userId || 'unauthorized'}

      if (process.env.REACT_APP_NODE_ENV !== 'development') {
        gtmSend(event)
      }
    }

    switch (activeSlide) {
      case 0:
        event = isAuth
        ? 'ind_prognosis_trial_flow_start_logined_user'
        : 'ind_prognosis_trial_flow_start_unlogined_user'
        break
      case 1:
        event = 'ind_prognosis_trial_flow_step2'
        break
      case 2:
        event = 'ind_prognosis_trial_flow_step3'
        break
      case 3:
        event = 'ind_prognosis_trial_flow_step4'
        break
      case 4:
        event = 'ind_prognosis_trial_flow_step5'
        break
    }

    gtmSend({'event': event, 'user_id': userId});
  }, [activeSlide])

  useEffect(() => {
    setTariff(tariffs.find(item => item.period === 1) || undefined)
  }, [tariffs])

  if(!tariff) return null;

  const onClick = () => {
    if (isAuth) {
      // The payment window was Changed. Task 6374.
      // openPaymentWindow(tariff, {
      //   freePeriod: props.freePeriod ? props.freePeriod : FreePeriod.TRIAL,
      //   trialPeriod
      // });

      openPaymentWithoutCard()
    } else {
      openEditWindow({
        beforeAuth: true,
        onProfileUpdate: () => {
          openSmsRegistrationWindow((channel: string, sessionData?: any) => {
            onAuthSuccess(channel, sessionData)
          })
        },
      })
    }

    setTimeout(() => {
      closePaywall();
    }, 700)

  }

	const onClickHandler = () => {
		if (activeSlide === (slides.length - 1)) {
			onClick()
		} else {
			setActiveSlide(prev => prev + 1)
		}
	}

  const signIn = () => {
		openSmsRegistrationWindow(onAuthSuccess)
    gtmSend({'event': 'ind_prognosis_trial_flow_signin', 'user_id': userId});
  }

  return <Container>
    <Slider slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide}/>
    <Button color="orange" onClick={onClickHandler} small>Далее</Button>
    {!isAuth && <Button color="transparent" onClick={signIn} small>Войти</Button>}
    <PaywallClose />
  </Container>
})

const Container = styled(WindowContainer)`
  width: 100%;
  height: 100%;
  background: var(--color-paywall-bg) url(${bgPath});
  background-size: cover;
  background-position: center center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem;
  z-index: 120;
`
