import dayjs from "dayjs";
import { openEditWindow } from "src/modules/edit/Edit";
import { windowsStore } from "src/store/Windows";
import styled from "styled-components";
import { ArrowSmall } from "../../../../assets/icons/system/system-icons";
import { getSignColor, TSignColor } from "../../../../helpers/colors";
import store from "../../../../store/Store";
import { PartnerDataType } from "../../../../store/Synastry";
import { PERSON, WINDOW_ID_CONFIRMATION } from "../../helpers/constants";
import { PersonalityOffer } from "./PersonalityOffer";
import ProductConfirmation from ".";
import { PersonalPerson } from "src/store/PersonalityDescription";


interface IPartnerItemProps {
  index?: number
  data: PartnerDataType
  signs: any
  product?: string
  hasTitle: boolean
  setEdit?(value: boolean): void
  setStep?(value: number): void
}

interface ITitles {
  SYNASTRY: { title: string, subtitle?: string }
  EMOTIONS: { title: string, subtitle?: string }
  SOLAR: { title: string, subtitle?: string }
}

function PersonCard(props: IPartnerItemProps) {
  const step = store?.isAuth ? props.index! + 1 : props.index! + 2
  const isFirstPartner = (store?.isAuth && step === 1) || (!store?.isAuth && step === 2)

  const titles: ITitles = {
    SYNASTRY: {
      title: `Партнер ${isFirstPartner ? '1' : '2'}`,
    },
    EMOTIONS: {
      title: 'Ваши данные',
    },
    SOLAR: {
      title: 'Проверьте ваши данные',
      subtitle: 'После оплаты вы не сможете поменять город рождения. Время рождения можно будет редактировать в пределах одних суток.'
    },
  }

  const onEdit = () => {
    if (props.product === PERSON) {
      const skipUpdateProfile = !!store.profile.email

      windowsStore.close(WINDOW_ID_CONFIRMATION)

      const onSubmit = (data: PersonalPerson) => {
        store.personalityDescription.setPerson(data)

        windowsStore.open(
          WINDOW_ID_CONFIRMATION,
          <ProductConfirmation
            product={'PERSON'}
            step={2}
          />,
          'fade'
        )
      }

      openEditWindow({
        beforeAuth: !store.isAuth,
        productPurchase: true,
        skipUpdateProfile: skipUpdateProfile,
        onProfileUpdate: (data) => onSubmit(data)
      })
    } else {
      props.setEdit && props.setEdit(true)
      props.setStep && props.setStep(step)
    }
  }

  const dateTime = dayjs.utc(props.data.dateTime).format('DD MMM YYYY, HH:mm')

  return (
    <Container>
      {
        props.hasTitle &&
        <>
          <div className={'header'}>{titles[props.product as keyof ITitles].title}</div>
          <div className={'subtitle'}>{titles[props.product as keyof ITitles]?.subtitle}</div>
        </>
      }

      <div className={'body'}>
        {
          props.signs && props.signs.map((sign: any, index: number) => {
            if (index === props.index) {
              const SignIcon = sign.icon
              const signColor = getSignColor(sign.key);
              return <SignContainer key={`SignContainer_${index}`} color={signColor}><SignIcon /></SignContainer>
            }
          })
        }

        <div className={'name'}>{props.data.name}</div>

        <div className={'text'}>{dateTime}, {props.data.place?.name}</div>

        <div className={'edit'} onClick={onEdit}>Редактировать <ArrowSmall/></div>
      </div>

      {props.product === PERSON && <PersonalityOffer/>}
    </Container>
  );
}

export default PersonCard;

const Container = styled.div`
  position: relative;
  margin-bottom: 2rem;
  z-index: 1;

  .header {
    display: flex;
    justify-content: space-between;

    color: var(--text-primary);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }

  .subtitle {
    color: var(--text-third);
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;

    color: var(--text-secondary);
    font-size: 0.9rem;
    background: #FFFFFF;
    box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
    border-radius: 1.5rem;
    padding: 1rem;

    .text > div {
      margin-bottom: 0.5rem;
    }


    .name {
      display: flex;
      align-items: center;

      color: var(--text-primary);
      font-size: 1.25rem;
      font-weight: 500;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    .edit {
      display: flex;
      align-items: center;

      color: var(--color-purpur);

      svg {
        width: 1rem;
        height: 1rem;
        margin-top: 0.2rem;
      }
    }
  }
`;

const SignContainer = styled.div<{ color?: TSignColor }>`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-shrink: 0;
  min-width: 3rem;
  min-height: 3rem;
  width: 2rem;
  height: 2rem;
  background: ${p => `var(--circle-zodiacs-elements-${p.color})`};
  border-radius: 50%;
  color: white;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  svg {
    width: 70%;
    height: 70%;
  }
`;
