import styled from "styled-components"

export default function Range({
    from,
    to,
    position,
    color
} : {
    from: string,
    to: string,
    position: number,
    color: string
}){
    const pos = (position + 100) / 2;
    // const pos = Math.min(100, Math.max(0, position));

    return <Container>
        <Line color={color}>
            <Circle color={color} left={pos} />
        </Line>
        <Texts>
            <div>{from}</div>
            <div>{to}</div>
        </Texts>
    </Container>
}

const Container = styled.div`
    margin-bottom: 1.5rem;
`

const Line = styled.div<{color: string}>`
    height: 6px;
    width: 100%;
    border-radius: 27px;
    background: ${p => p.color};
    margin-bottom: 0.75rem;
    position: relative;
`

const Texts = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
`

const Circle = styled.div<{color: string, left: number}>`
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: -3px;
    left: ${p => p.left}%;
    background: ${p => p.color};
    border: 2px solid #FFFFFF;
`