import { useEffect } from "react";

export function utfIcon(icon: string) {
    if(!icon) return <div></div>;
    return <div dangerouslySetInnerHTML={{__html: icon.replace('U+', '&#x') + ';'}}></div>;
}

export function hashCode(str: string) {
    let hash = 0, i, chr;
    if (str.length === 0) return String(hash);
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return String(hash);
  }

export const relationsPartnerName = 'Ожидание от партнера';
export const relationsHashCode = hashCode(relationsPartnerName);

export function useScrollToBlock(scrollTo?: string) {
    useEffect(() => {
        if(scrollTo) {
            const el = document.getElementById(`scrollTo${scrollTo}`);
            console.log(1, el)
            if(el) {
            const w = el.closest('#Person2Window');
            console.log(2, w)
            w && w.scrollTo(0, el.getBoundingClientRect().top - 60);
            }
            
        }
    }, [scrollTo])
}
