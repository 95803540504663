import { makeAutoObservable } from "mobx";
import { IPlace, toDateTime } from '../libs';
import { getPersonalityDescriptionPersonDataLS, setPersonalityDescriptionPersonDataLS } from "./localStorageHelpers";
import { Indi } from '../libs';
import api from "src/helpers/api";


export interface PersonalPerson {
  name: string
  dateTime: string
  place: IPlace | null
  gender: string | null
}

const defaultPerson: PersonalPerson = {
  name: '',
  dateTime: '',
  place: null,
  gender: null,
}

export class PersonalityDescription {
  constructor() {
    makeAutoObservable(this)
  }

  setPerson(data: PersonalPerson) {
    setPersonalityDescriptionPersonDataLS(data)
  }

  getPerson() {
    const person = getPersonalityDescriptionPersonDataLS()

    if (person) {
      return person
    } else {
      return defaultPerson
    }
  }

  getPersonEdit() {
    return this.formatPersonData(this.getPerson())
  }

  getPersonData() {
    const data: any = {}
    const person = this.getPerson()
    const {date, time} = toDateTime(person.dateTime ?? '')

    data.name = person.name
    data.date = date
    data.time = time
    data.place = person.place?.name
    data.lat = person.place?.lat
    data.lon = person.place?.lon
    data.gender = person.gender

    return data
  }

  formatPersonData(person: PersonalPerson) {
    const formattedPerson = {
      firstName: person.name,
      birth: {
        dateTime: person.dateTime,
        place: person.place
      },
      originalDate: '',
      email: '',
      gender: person.gender,
    }

    return formattedPerson
  }

  resetPersonalityDescription() {
    setPersonalityDescriptionPersonDataLS(defaultPerson)
  }

  async freeChargePerson(data: Indi.Person.CalculateFreePerson.Request) {
    try {
      const result = await api.indiCalculateFreePerson(data)
      return result
    } catch(e) {
      console.error(`Getting free Person error: ${e}`)
    }
  }
}
