import styled from "styled-components"
import { ReactComponent as StarIcon } from '../assets/star.svg';
import { FlexContainer } from "src/components/layout/elements";
import { useEffect, useState } from "react";
import { MoreButton } from "./PersonMainBlock";
import PersonFeedbackForm from "./PersonFeedbackForm";
import api from "src/helpers/api";



export default function PersonFeedback() {

    const [rating, setRating] = useState(0);
    const [showRating, setShowRating] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const loadData = async () => {
        try {
            await api.indiGetPersonFeedbackByUser();
            setShowRating(true);
        } catch(error) {
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    if(showRating) return null;

    const onClick = (index: number) => {
        setRating(index + 1);
    }

    const onSubmit = () => setShowForm(true);

    const onClose = () => {
        setShowForm(false);
        loadData();
    }

    return <Container>
        <Title>Было полезно?</Title>
        <Text>Пожалуйста, оцените ваши впечатления<br /> об Описании личности </Text>
        <Stars>
            {Array(5).fill(0).map((_item, index) => 
                <Star key={index} onClick={() => onClick(index)}  className={rating > index ? 'active' : ''}>
                    <StarIcon  />
                </Star>
            )}
        </Stars>
        <MoreButton disabled={rating === 0} onClick={onSubmit}>Оценить</MoreButton>

        {showForm && <PersonFeedbackForm onClose={onClose} rating={rating} />}

    </Container>
}

export const Container = styled.div`
    margin-bottom: 2rem;
    padding: 2.375rem 1rem 0.875rem;
    text-align: center;

    border: 0.5px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 4px 14px 0px rgba(129, 136, 157, 0.1);
    color: var(--text-primary);

`

export const Title = styled.div`
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
`

export const Text = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
`

export const Stars = styled(FlexContainer)`
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
`

export const Star = styled.div`
    &.active{
        path {
            fill: rgba(244, 149, 80, 1);
            stroke: rgba(244, 149, 80, 1);
        }
    }
`
