import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { DealAcceptance } from 'src/components/DealAcceptance';
import { LogoChronosPlus } from 'src/components/LogoChronosPlus';
import { CheckboxContainer } from 'src/components/ui/CheckboxContainer';
import { Errors, getErrorInfo } from 'src/helpers/error';
import styled from 'styled-components';
import { ChevronRightIcon } from '../../assets/icons/system/system-icons';
import background from '../../assets/images/backgrounds/bg_component.jpg';
import { defaultDateTime } from '../../components/PersonInfo';
import { Banner } from '../../components/banners/Banner/Banner';
import { PrognosesInputs } from '../../components/forms/PrognosesInputs';
import { MainPadding, WindowContainer } from '../../components/layout/elements';
import { Button } from '../../components/ui/Button/Button';
import api, { s3urls } from '../../helpers/api';
import { isConsentInvalid, isDateInvalid, isEmailInvalid, isNameInvalid, isPlaceInvalid, isTimeInvalid } from '../../helpers/fields';
import { ROUTES } from '../../helpers/routes';
import { gtmSend, searchParams } from '../../helpers/url';
import { useBackWindow } from '../../hooks/router-hooks';
import { fromDateTime, Gender, IPlace } from '../../libs';
import store from '../../store/Store';
import { windowsStore } from '../../store/Windows';
import { openConsultationSignUp } from '../consultation-sign-up/ConsultationSignUp';
import { CopyRight, defaultPlace } from '../registration/Registration';
import { IconStyles, WindowHeader } from '../windows/components/WindowHeader';


export const WINDOW_ID_EDIT: string = 'WINDOW_ID_EDIT';

export interface GenderItem {
  value: Gender
  title: string
}

const genderItems: GenderItem[] = [
  {
    value: 'female',
    title: 'Женский',
  },
  {
    value: 'male',
    title: 'Мужской',
  },
]

interface EditProps {
  beforeAuth?: boolean;
  onProfileUpdate?: (data?: any) => void;
  beforePayment?: boolean;
  showToaster?: () => void;
  direct?: boolean;
  skipUpdateProfile?: boolean
  productPurchase?: boolean
  emailError?: string
}

export const openEditWindow = (props?: EditProps) => {
  windowsStore.open(
    WINDOW_ID_EDIT,
    <Edit
      beforeAuth={props?.beforeAuth}
      beforePayment={props?.beforePayment}
      onProfileUpdate={props?.onProfileUpdate}
      showToaster={props?.showToaster}
      direct={props?.direct}
      skipUpdateProfile={props?.skipUpdateProfile}
      productPurchase={props?.productPurchase}
      emailError={props?.emailError}
    />,
    'fade'
  )
}

export const Edit = observer((props: EditProps) => {
  useBackWindow(ROUTES.EDIT, WINDOW_ID_EDIT);

  const {
    firstName,
    birth: {
      dateTime,
      place: initialPlace
    },
    originalDate,
    email,
    gender,
  } = props.productPurchase
        ? store.personalityDescription.getPersonEdit()
        : store.profile;

  const initialDate = dayjs.utc(dateTime || defaultDateTime)?.toISOString();
  const initialTime = dayjs.utc(dateTime || defaultDateTime)?.toISOString();

  const [name, setName] = useState(firstName ?? '');
  const [nameError, setNameError] = useState('');

  const [date, setDate] = useState(initialDate ?? '');
  const [dateError, setDateError] = useState('');

  const [time, setTime] = useState(initialTime ?? '');
  const [timeError, setTimeError] = useState('');

  const [place, setPlace] = useState<IPlace>(initialPlace || defaultPlace);
  const [placeError, setPlaceError] = useState('');

  const [currentEmail, setCurrentEmail] = useState(email ?? '');
  const [emailError, setEmailError] = useState(props.emailError ? 'Эта почта уже зарегистрирована' : '');

  const getGender = (value?: string | null) => genderItems.find(item => item.value === value) ?? genderItems[0]

  const [currentGender, setCurrentGender] = useState(getGender(gender));

  const [disableButton, setDisableButton] = useState(false);

  const [consentFirst, setConsentFirst] = useState(true);
  const [consentSecond, setConsentSecond] = useState(true);
  const [consentError, setConsentError] = useState('');

  const rectificationDelay = store.rectification.rectificationDelay
  const rectificationSent = store.rectification.rectificationSent
  const userId = store.sessionData?.id || 'unauthorized';

  const sp = searchParams()

  const hideEmail = props.productPurchase && !props.beforeAuth
  const showRectification = !rectificationDelay && !props.beforeAuth && !props.productPurchase
  const signUpOrPurchase = props.beforeAuth || props.productPurchase
  const isAuth = store.isAuth

  const profile = store.profile

  useEffect(() => {
    const sp = searchParams()
    const event = !props.direct
      ? 'indi_prognosis_personal-info_visit'
      : 'indi_prognos_pers-info_visit-light'

    gtmSend({
      'event': event,
      'userId': userId,
      'utm_source': sp.get('utm_source'),
    })
  }, [])

  useEffect(() => {
    checkDate(date, originalDate);
  }, [date, originalDate])

  const checkDate = (date: string, originalDate?: string) => {
    const dateError: string = isDateInvalid(date, originalDate);
    if (dateError) {
      setDateError(dateError);
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }

  const changeDateFormat = (value: string) => {
    return dayjs(value).format('YYYY-MM-DD')
  }

  const minDate = originalDate && changeDateFormat(dayjs(originalDate, 'DD.MM.YYYY').subtract(1, 'day').toString())
  const maxDate = originalDate && changeDateFormat(dayjs(originalDate, 'DD.MM.YYYY').add(1, 'day').toString())

  const onClose = () => windowsStore.close(WINDOW_ID_EDIT);

  const submit = async () => {
    try {
      if (checkInvalidData()) return

      if (props.direct) {
        gtmSend({'event': 'indi_profile_my_recti_data_done-light', 'user_id': userId})
      }

      if (!props.beforeAuth && !props.beforePayment ) {
        gtmSend({'event': 'indi_profile_my_recti_data_done', 'user_id': userId})
      }

      const newProfile = {
        firstName: name,
        birth: {
          dateTime: {
            date: dayjs.utc(date).format('DD.MM.YYYY'),
            time: dayjs.utc(time).format('HH:mm:ss')
          },
          place
        },
        email: currentEmail,
        gender: currentGender.value,
      }

      if (!props.skipUpdateProfile) {
        if (props.beforeAuth) {
          await store.updateProfile(store.convertProfile({...store.profile, ...newProfile}))
        } else {
          await api.updateProfile(newProfile as any) // FIXME: any
        }
      }

      await store.getProfile()

      const formattedProfile = {
        name,
        dateTime: fromDateTime(dayjs.utc(date).format('DD.MM.YYYY'), dayjs.utc(time).format('HH:mm:ss')),
        gender: currentGender.value,
        place,
      }

      props.onProfileUpdate?.(formattedProfile)
      onClose()
    } catch (e) {
      console.error(`Submit error: ${e}`)

      const errorInfo = getErrorInfo(String(e))
      if (errorInfo.message === Errors.EMAIL_OR_PHONE_EXIST) {
        setEmailError('Эта почта уже зарегистрирована')
      }
    }
  }

  const onChangeNameHandler = (value: string) => {
    setName(value);
    setNameError('');
  }

  const onFocusNameHandler = () => {
    if (props.beforeAuth) {
      const event = !props.direct
        ? 'indi_prognosis_personal-info_name_click'
        : 'indi_prognos_pers-info_name_click-light'
      gtmSend({
        'event': event,
        'userId': userId,
        'utm_source': sp.get('utm_source'),
      })
    }
  }

  const onChangeDateHandler = (value: string) => {
    setDate(value);
    setDateError('');
  }

  const onFocusDateHandler = () => {
    if (props.beforeAuth) {
      const event = !props.direct
        ? 'indi_prognosis_personal-info_date_click'
        : 'indi_prognos_pers-info_date_click-light'

      gtmSend({
        'event': event,
        'userId': userId,
        'utm_source': sp.get('utm_source'),
      })
    }
  }

  const onChangeTimeHandler = (time: string) => {
    setTime(time)
    setTimeError('')
  }

  const onFocusTimeHandler = () => {
    if (props.beforeAuth) {
      const event = !props.direct
        ? 'indi_prognosis_personal-info_time_click'
        : 'indi_prognos_pers-info_time_click-light'

      gtmSend({
        'event': event,
        'userId': userId,
        'utm_source': sp.get('utm_source'),
      })
    }
  }

  const onChangePlaceHandler = (value: any) => {
    if (typeof value === 'object') {
      setPlace({ name: value.name, lat: value.lat, lon: value.lon })
      setPlaceError('')
    } else {
      setPlace({ name: value, lat: 0, lon: 0 });
    }
  }

  const onFocusPlaceHandler = () => {
    if (props.beforeAuth) {
      const event = !props.direct
        ? 'indi_prognosis_personal-info_place_click'
        : 'indi_prognos_pers-info_place_click-light'

      gtmSend({
        'event': event,
        'userId': userId,
        'utm_source': sp.get('utm_source'),
      })
    }
  }

  const onChangeEmailHandler = (value: string) => {
    setCurrentEmail(value.trim())
    setEmailError('')
  }

  const onFocusEmailHandler = () => {
    if (props.beforeAuth) {
      gtmSend({
        'event': 'indi_prognos_pers-info_email_click',
        'userId': userId,
        'utm_source': sp.get('utm_source'),
      })
    }
  }

  const onChangeConsentFirstHandler = (value: boolean) => {
    setConsentFirst(value)
    setConsentError('')
  }

  const onChangeConsentSecondHandler = (value: boolean) => {
    setConsentSecond(value)
    setConsentError('')
  }

  const checkInvalidData = () => {
    if (dateError || timeError) return true;

    const { nameErr, dateErr, placeErr, timeErr, emailErr, consentErr } = {
      nameErr: isNameInvalid(name),
      dateErr: isDateInvalid(date!),
      timeErr: isTimeInvalid(time!),
      placeErr: isPlaceInvalid(place),
      emailErr: hideEmail ? '' : isEmailInvalid(currentEmail),
      consentErr: props.beforeAuth ? isConsentInvalid(consentFirst) || isConsentInvalid(consentSecond) : '',
    }

    if (nameErr) setNameError(nameErr);
    if (dateErr) setDateError(dateErr);
    if (timeErr) setTimeError(timeErr);
    if (placeErr) setPlaceError(placeErr);
    if (emailErr) setEmailError(emailErr);
    if (consentErr) setConsentError(consentErr);

    return nameErr || dateErr || timeErr || placeErr || emailErr || consentErr
  }

  const orderRectificationSubmit = async () => {
    await store.userProfile.getProfile();
    const phoneNumber = store.userProfile.profile?.phoneNumber;
    if (!phoneNumber) {
      openConsultationSignUp(orderRectification, 'Запись на ректификацию');
    } else {
     orderRectification();
    }
  };

  const orderRectification = () => {
    store.rectification.orderRectification()
    gtmSend({'event': 'indi_profile_rectification_my_recti_data', 'user_id': userId})
  }

  const mailingAgreement = (
    <StyledCopyRight>
      Я принимаю&nbsp;
      <a href={s3urls.mailingAgreementRU} target="_blank" rel="noreferrer">соглашение на рассылку</a>
    </StyledCopyRight>
  )

  const useProfileData = () => {
    setName(profile.firstName)
    setDate(profile.birth.dateTime!)
    setTime(profile.birth.dateTime!)
    setPlace(profile.birth.place!)
    setCurrentGender(getGender(profile.gender))
  }

  return <StyledWindowContainer background={background}>
    <StyledWindowHeader
      title={signUpOrPurchase ? '' : 'Мои данные'}
      onBack={signUpOrPurchase ? undefined : onClose}
      onClose={signUpOrPurchase ? onClose : undefined}
      iconStyle={IconStyles.WITH_BG}
    />

    {
      signUpOrPurchase && (
        <Header>
          <LogoChronosPlus/>
          <div className={'title'}>Для построения Chronos использует ваш день рождения</div>

          {
            isAuth && props.productPurchase && (
              <Button
                onClick={useProfileData}
                color={'gray'}
              >
                Ввести свои данные из аккаунта
              </Button>
            )
          }
        </Header>
      )
    }

    <Content>
      <PrognosesInputs
        name={name}
        nameOnChange={onChangeNameHandler}
        nameError={nameError}
        namePlaceholder={'Как вас зовут?'}
        nameOnFocus={onFocusNameHandler}
        date={date!}
        dateOnChange={onChangeDateHandler}
        dateOnInvalid={() => setDateError('Введите корректную дату')}
        dateError={dateError}
        dateOnFocus={onFocusDateHandler}
        time={time!}
        timeOnChange={onChangeTimeHandler}
        timeOnInvalid={() => setTimeError('Введите корректное время')}
        timeError={timeError}
        timeOnFocus={onFocusTimeHandler}
        place={place}
        placeOnChange={onChangePlaceHandler}
        placeError={placeError}
        placePlaceholder={'Где вы родились?'}
        placeOnFocus={onFocusPlaceHandler}
        minDate={minDate}
        maxDate={maxDate}
        originalDate={originalDate}
        email={hideEmail ? undefined : currentEmail}
        emailOnChange={hideEmail ? undefined : onChangeEmailHandler}
        emailError={hideEmail ? undefined : emailError}
        emailOnFocus={hideEmail ? undefined : onFocusEmailHandler}
        gender={currentGender}
        genderOnChange={setCurrentGender}
        genderItems={genderItems}
      />

      {
        props.beforeAuth && (
          <>
            <CheckboxContainer
              checked={consentFirst}
              onChange={onChangeConsentFirstHandler}
              text={<DealAcceptance/>}
              color={'var(--color-cornflower-blue)'}
            />

            <CheckboxContainer
              checked={consentSecond}
              onChange={onChangeConsentSecondHandler}
              text={mailingAgreement}
              error={consentError}
              color={'var(--color-cornflower-blue)'}
              iconError={false}
            />
          </>
        )
      }

      {
        showRectification &&
          <StyledBanner
            title='Узнайте точное время рождения'
            text='Свяжемся с вами, чтобы рассказать об услуге ректификации (уточнение времени рождения)'
            buttonContent={
              <ButtonContent>
                {rectificationSent ? 'Заявка отправлена' : 'Отправить заявку'}
                {!rectificationSent && <ChevronRightIcon />}
              </ButtonContent>}
            onClick={orderRectificationSubmit}
            rectificationSent={rectificationSent}
          />
      }
    </Content>

    <Footer>
      <Button
        onClick={submit}
        disabled={disableButton}
        color='gradient_purpure'
      >
        {signUpOrPurchase ? 'Продолжить' : 'Сохранить'}
      </Button>
    </Footer>
  </StyledWindowContainer>
})

const StyledWindowContainer = styled(WindowContainer)<{background: string}>`
  background: ${p => `url(${p.background}) 0 0 / cover no-repeat`};
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 1rem;

  .title {
    font-weight: 500;
    color: var(--text-secondary);
    text-align: center;
    width: 80%;
    margin: 1.125rem 0 1.5rem 0;
  }
`

const Content = styled(MainPadding)`
  flex: 1;
`

const Footer = styled(MainPadding)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: min-content;
`

const StyledBanner = styled(Banner)<{ rectificationSent?: boolean }>`
  margin-top: 1.5rem;

  .title {
    font-size: 1.125rem;
    color: var(--text-primary);
    width: 100%;
  }

  .text {
    color: var(--text-primary);
  }

  .banner_button {
    margin-top: 1.25rem;
    width: fit-content;
    background-color: ${({ rectificationSent }) => (rectificationSent ? '#0000000A' : 'var(--text-primary)')};
    color: ${({ rectificationSent }) => (rectificationSent ? 'black' : 'inherit')};
    transition: background-color 0.3s, color 0.3s;
  }
`

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.875rem;
  padding: 0 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-white);
  }
`

const StyledWindowHeader = styled(WindowHeader)`
  justify-content: space-between;
`

const StyledCopyRight = styled(CopyRight)`
  padding: 0;
  text-align: left;

  a {
    text-decoration: underline;
  }
`
