import { observer } from "mobx-react-lite"
import { getArticleWord } from "src/helpers/words"
import { getProductTitle } from "src/modules/products/helpers/content"
import { INote } from "src/store/Notes"
import styled, { css } from "styled-components"
import { IndiProductCode } from "../../../../libs"
import { Note } from "../Note"
import { ChevronDownIcon } from "src/assets/icons/system/system-icons"
import { useState } from "react"
import { openPerson } from "src/modules/products/products/Person/Person"
import { sendProductGtm } from "src/modules/products/helpers/gtm"
import store from "src/store/Store"


interface ProductsNotesProps {
  product: IndiProductCode
  notes: INote[]
}

const words = ['рекомендация', 'рекомендации', 'рекомендаций']

export const ProductsNotes = observer((props: ProductsNotesProps) => {
  const {
    product,
    notes,
  } = props

  const [showNotes, setShowNotes] = useState(true);

  const userId = store.sessionData?.id;

  const productNotes = notes.filter(note => note.product && note.product === product)

  const getCounter = () => {
    const count = productNotes.reduce((acc, next) => acc + next.recommendations.length, 0)
    return `${count} ${getArticleWord(words, count)}`
  }

  const toggleShowNotes = () => {
    setShowNotes(!showNotes)
  }

  const goTo = (token: number, type?: string, screenLevel?: number, title?: string) => {
    sendProductGtm('PERSON', 'note_back_rec', userId);

    store.notes.setDataOpen({
      type,
      screenLevel,
      title,
    })

    openPerson(token)
  }

  if (productNotes.length === 0) return null

  return (
    <Container>
      <Header>
        <div>
          <Title>{getProductTitle(product)}</Title>
          <Counter>{getCounter()}</Counter>
        </div>

        <IconContainer
          showNotes={showNotes}
          onClick={toggleShowNotes}
        >
          <ChevronDownIcon/>
        </IconContainer>
      </Header>

      <Content showNotes={showNotes}>
        {
          productNotes.map((note, index) => (
            <Note
              key={`product_note_${index}_${note.id}`}
              note={note}
              background={'rgba(165, 232, 97, 0.1)'}
              color={'rgba(165, 232, 97, 1)'}
              goToEvent={() => goTo(note.productId!, note.type, note.screenLevel, note.title)}
              showInterval={false}
            />
          ))
        }
      </Content>
    </Container>
  )
})

const Container = styled.div`
  margin-bottom: 1.5rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
`

const Counter = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--text-third);
  margin-top: 0.5rem;
`

const IconContainer = styled.div<{showNotes: boolean}>`
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-white);
  border-radius: 50%;
  box-shadow: 0px 0.75px 2px 0px #8992AC1F;
  box-shadow: 0px 4px 14px 0px #81889D14;

  ${p => p.showNotes && css`
    svg {
      transform: rotate(180deg);
    }
  `}
`

const Content = styled.div<{showNotes: boolean}>`
  margin-top: 1.25rem;
  display: ${p => p.showNotes ? 'block' : 'none'};
`
