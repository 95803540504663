import { makeAutoObservable } from 'mobx';
import Api from "../helpers/api";
import { searchParams } from '../helpers/url';
import { setConfirmationStepLS, setProductDataLS, setProductTokenLS } from './localStorageHelpers';
import store from './Store'
import { IEmoji, Indi } from '../libs';
import { dailyPractices, IBlog } from 'src/modules/blog/helpers/blog';

interface IPrice {
  code: string
  id: number
  productId: number
  value: number
}

export interface IProduct {
  code: string
  description: string
  emoji: IEmoji
  id: number
  name: string
  price: IPrice
  version: string
  removed: boolean | null
}

export interface IPurchasedProduct {
  code: string
  createdAt: string
  emoji: IEmoji
  id: number
  name: string
  paidProductTableId: number
  paymentId: string
  productId: number
  userId: number
  version: string
  isTrial: boolean
  partner1?: {
    date: string
    name: string
    time: string
  }
  partner2?: {
    date: string
    name: string
    time: string

  }
  solarBirthDate?: string
  periods?: {
    end: string
    start: string
  }[]
  place1?: string
  place2?: string;
  data?: UserData
}

export interface UserData {
  name: string
  date: string
  forUserEmail: string
  gender: string
  gmt: number | null
  language: string
  lat: number
  lon: number
  originDate: string
  originTime: string
  paymentId: string
  place: string
  user_id: number
}

export default class Products {
  constructor() {
    makeAutoObservable(this);
  }
  blogs: IBlog[] = [];
  banners: Indi.Banner[] = [];
  products: IProduct[] = []
  productData: IProduct | null = null
  purchasedProducts: IPurchasedProduct[] = []
  activeProductToken: number | null = null

  isSharedProduct: string | null = null

  async getProducts() {
    const result: IProduct[] = await Api.indiGetProducts({currencyCode: 'RUB'}) as any;
    const noEmotions = searchParams().get('no-emotions');
    if(noEmotions) this.products = result.filter(item => +item.id !== 2);
    else this.products = result
    return result
  }

  async getArticlesGroups() {
    try {
      const response = await Api.indiGetAdminPageInfo();
      const banners: Indi.Banner[] = response.banners.filter((banner: any) => !banner.hidden)
      const blogs: IBlog[] = response.blogs.filter((blog: any) => !blog.hidden)
      .map((blog: any) => ({
        title: blog.title,
        articles:blog.hidden
        ? []
        : blog.articles.filter((article: any) => !article.hidden),
    }));
      this.blogs = [...blogs, dailyPractices];
      this.banners = banners;
    } catch (error) {
      console.error('Ошибка при загрузке:', error);
    }
  }

  async getPurchasedProducts() {
    const result: IPurchasedProduct[] = await Api.indiGetPurchasedProducts() as any;
    this.purchasedProducts = result.sort((a,b) => a.id < b.id ? 1 : -1);
  }

  async checkProduct(paymentId: string) {
    const result = await Api.indiIsProductPaid({paymentId})
    return result
  }

  setProductData(data: IProduct) {
    this.productData = data
  }

  setIsSharedProduct(value: string | null) {
    this.isSharedProduct = value
  }

  selectProduct(data: IProduct) {
    const token = data.id;
    store.payments.setToken(token)
    setProductTokenLS(token.toString())
    store.products.setProductData(data)
    setProductDataLS(data)
    store.promocode.resetPromocode()
    setConfirmationStepLS('')
  }

  setActiveProductToken(token: number) {
    this.activeProductToken = token
  }

  removeActiveProductToken() {
    this.activeProductToken = null
  }
}
