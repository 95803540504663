import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import styled from 'styled-components';
import { MainPadding, WindowContainer } from '../../../../components/layout/elements';
import { ROUTES } from '../../../../helpers/routes';
import { searchParams } from '../../../../helpers/url';
import { useBackWindow } from '../../../../hooks/router-hooks';
import { getConfirmationStepLS, setConfirmationStepLS } from '../../../../store/localStorageHelpers';
import { IProductCode } from "../../../../store/Payments";
import store from '../../../../store/Store';
import { windowsStore } from '../../../../store/Windows';
import { IconStyles, WindowHeader } from '../../../windows/components/WindowHeader';
import { EMOTIONS, PERSON, SOLAR, SYNASTRY, WINDOW_ID_CONFIRMATION } from '../../helpers/constants';
import Form from "./Form";
import Payment from "./Payment";


interface IProductConfirmation {
  product: IProductCode
  step?: number
}

export const openProductConfirmation = (props: IProductConfirmation) => {
  windowsStore.open(
    WINDOW_ID_CONFIRMATION,
    <ProductConfirmation
      product={props.product}
      step={props.step}
    />
  )
}

const ProductConfirmation = observer(function (props: IProductConfirmation) {
  useBackWindow(ROUTES.PRODUCTS_CONFIRMATION, WINDOW_ID_CONFIRMATION);

  const getProductTitle = (product: IProductCode, full?: boolean) => {
    switch (product) {
      case SYNASTRY:
        return full ? 'Совместимость в любви' : 'Синастрия'
      case EMOTIONS:
        return 'Эмоции'
      case SOLAR:
        return full ? 'Солярный день рождения' : 'Солярный день'
      case PERSON:
        return 'Описание личности'
      default:
        return ''
    }
  }

  document.title = `${getProductTitle(props.product, true)} • Ввод данных`;

  const getStep = (): number => {
    const stepLS = getConfirmationStepLS()
    if (stepLS) return +stepLS
    if (props.step) return props.step
    return 1
  }

  const [step, setStep] = useState(getStep())
  const [edit, setEdit] = useState(false)

  const isAuth = store.isAuth
  const sp = searchParams()

  const getLastStep = (product: string) => {
    switch (product) {
      case SYNASTRY:
        return isAuth ? 3 : 4
      case EMOTIONS:
      case SOLAR:
      case PERSON:
        return isAuth ? 2 : 3
      default:
        return 0
    }
  }

  const onClose = () => {
    if (edit) {
      setEdit(false);
      setStep(getLastStep(props.product));
    } else {
      windowsStore.close(WINDOW_ID_CONFIRMATION)
    }
  }

  const onSetStepHandler = (value: number) => {
    setStep(value)
    setConfirmationStepLS(value.toString())
  }

  return (
    <WindowContainer>
      <StyledWindowHeader
        title={step === getLastStep(props.product) ? 'Корзина' : ''}
        onClose={onClose}
        iconStyle={IconStyles.WITH_BG}
      />

      <Content>
        {
          (step < getLastStep(props.product) || edit) &&
          <Form product={props.product}
                edit={edit}
                lastStep={getLastStep(props.product)}
                setEdit={(value: boolean) => setEdit(value)}
                step={step}
                setStep={onSetStepHandler}/>
        }

        {
          step === getLastStep(props.product) && !edit && (
            <Payment
              product={props.product}
              lastStep={getLastStep(props.product)}
              setEdit={(value: boolean) => setEdit(value)}
              setStep={onSetStepHandler}
              productTitle={getProductTitle(props.product)}
            />
          )
        }
      </Content>
    </WindowContainer>
  );
})

export default ProductConfirmation

const Content = styled(MainPadding)`
  position: relative;
  height: 100%;
  padding-top: 0;
`

const StyledWindowHeader = styled(WindowHeader)`
  justify-content: space-between;
`
