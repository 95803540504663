import React from 'react';
import styled, {css} from 'styled-components'

export interface IOption {
  label: string;
  value: any;
}

export default function ButtonSwitcher(props: {
  options: IOption[];
  value: any;
  disabled?: boolean;
  onChange(v: any): void;
	className?: string;
}): JSX.Element {

  const changeHandler = (value: any) => {
    if (!props.disabled) props.onChange(value)
  }

  return <ButtonSwitcherContainer className={`${props.className} no-print`} disabled={props.disabled || false}>
    {props.options.map((o, i) => {
      return <Button
        key={`button_switcher_${i}`}
        active={props.value === o.value}
        onClick={() => changeHandler(o.value)}
      >
        {o.label}
      </Button>
    })}
  </ButtonSwitcherContainer>;
}

const ButtonSwitcherContainer = styled.div<{ disabled: boolean }>`
  background: var(--input-background);
  border-radius: 53px;
  padding: 4px;
  box-sizing: border-box;
  backdrop-filter: blur(48px);
  display: flex;
  width: 100%;

  ${props => props.disabled && css`
    opacity: 0.6;
    > button {
      cursor: default;
    }
  `}
`;

const Button = styled.button<{ active: boolean; }>`
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 55px;
  width: 100%;
  height: 40px;
  display: flex;
  color: var(--text-secondary);
	font-size: 1rem;
	font-weight: 500;
  transition: all 0.2s ease;
  font-family: 'Apercu Pro', sans-serif;
  cursor: pointer;

  ${props => props.active && css`
    background: #FFFFFF;
    box-shadow: 0 3.2px 14px rgba(0, 0, 0, 0.06), 0 0.6px 1.8px rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
  `}
`;
