import { gtmSend } from "src/helpers/url"
import { EMOTIONS, PERSON, SOLAR, SYNASTRY } from "./constants"

export const sendProductGtm = (
  product: string,
  event: string,
  userId?: number
) => {
  let code = ''

  switch (product) {
    case SYNASTRY:
    case EMOTIONS:
    case SOLAR:
      code = product.toLowerCase()
      break
    case PERSON:
      code = 'persdescr'
      break
  }

  gtmSend({
    event: `indi_${code}_${event}`,
    user_id: userId ?? '',
  })
}
