export function searchParams() {
  return new URLSearchParams(window.location.search);
}

export const gtmSend = (data: any) => {
  window.dataLayer.push(data)
}

export const mailTo = (email: string) => window.location.href = `mailto:${email}`;

export const getPathnames = () => window.location.pathname.split('/')

export function getUTMString() {
  const sp = searchParams()
  const regex = /utm/
  let string = ''

  for (let key of sp.keys()) {
    if (key && regex.test(key)) {
      string += `${key}=${sp.get(key)}&`;
    }
  }

  return string
}