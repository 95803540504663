import { IndiProductCode } from '../../../libs'

export const getProductTitle = (product: IndiProductCode, full?: boolean) => {
  switch (product) {
    case IndiProductCode.SYNASTRY:
      return full ? 'Совместимость в любви' : 'Синастрия'
    case IndiProductCode.EMOTIONS:
      return full ? 'Контакт со своими эмоциями' : 'Эмоции'
    case IndiProductCode.SOLAR:
      return full ? 'Солярный день рождения' : 'Солярный день'
    case IndiProductCode.PERSON:
      return 'Описание личности'
    default:
      return ''
  }
}
